@use 'variables/_color' as color;
@use 'bootstrap' as bootstrap;

ngx-slider {
  &.ngx-slider {
    &:not(.ngx-slider-vertical) {
      margin: 0 !important;
      margin-top: 15px !important;
      height: 45px !important;

      .ngx-slider-tick-value {
        top: 25px !important;
        color: color.$primary !important;
        font-weight: bold;
      }

      &.custom {
        .ngx-slider-tick {
          background: none !important;
        }

        .ngx-slider-bar {
          height: 22px !important;
          background: none !important;
          border: 5px solid color.$gray;
          border-radius: 10px !important;
          top: 8px !important;
        }
      }
    }

    .ngx-slider-pointer {
      background-color: color.$primary !important;

      &:hover {
        background-color: color.$primary !important;
      }

      &::after {
        background-color: color.$primary !important;
      }
    }
  }

  &[disabled] {
    &:not(.ngx-slider-vertical) {
      &.custom {
        .ngx-slider-bar {
          background: bootstrap.$input-disabled-bg !important;
        }
      }
    }
  }
}
