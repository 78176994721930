@use "variables/color";

.custom-control.custom-switch {
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none;
  }
}

.custom-control.custom-switch.primary {
  .custom-control-input:checked:not(:disabled) ~ .custom-control-label::before {
    border-color: color.$green-2;
    background-color: color.$green-2;
  }

  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: color.$green-2;
  }

  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    border-color: color.$green-2;
  }
}