@use "variables/color";

#layout-migrating {
  min-height: 100dvh;
  background-color: color.$white;

  #logo {
    width: 250px;
  }

  .container {
    max-width: 1100px;
    padding-top: 60px;
  }

  a.btn-link {
    text-transform: lowercase !important;
    vertical-align: baseline !important;
  }

  .validation-error {
    display: none;
    color: color.$danger;
  }

  form.ng-submitted.ng-invalid.on-submit-verification {
    .validation-error {
      display: block;
    }
  }
}