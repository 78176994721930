svg-icon {
  svg {
    vertical-align: top !important;
  }

  &.icon-center {
    svg {
      vertical-align: baseline !important;
    }
  }
}
