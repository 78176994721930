@use 'variables/_color' as color;
@use 'bootstrap' as bootstrap;

.form-group {
  width: 100%;

  label {
    font-weight: 400;
  }
}

.input-group {
  .input-group-prepend {
    .input-group-text {
      border-top-left-radius: bootstrap.$input-border-radius;
      border-bottom-left-radius: bootstrap.$input-border-radius;
    }
  }

  .input-group-append {
    .input-group-text {
      border-top-right-radius: bootstrap.$input-border-radius;
      border-bottom-right-radius: bootstrap.$input-border-radius;
    }
  }
}

input.form-control {
  &.with-content {
    padding-right: 50px;
  }
}

.form-control-plaintext {
  width: auto !important;
  height: auto !important;
}

.is-invalid ~ .invalid-feedback {
  display: block;
}

form.ng-submitted.on-submit-verification {
  .ng-invalid ~ .invalid-feedback {
    display: block;
  }
}

textarea.form-control {
  padding: 8px;
}
