@use '_color' as color;
@use 'sass:color' as sass-color;

$body-bg: color.$gray;
$body-color: color.$black;

$primary: color.$primary;
$secondary: color.$secondary;
$success: color.$success;
$danger: color.$danger;
$yiq-contrasted-threshold: 165;

$dropdown-link-hover-bg: sass-color.adjust(color.$primary, $lightness: 60%);
$dropdown-link-active-bg: color.$primary;

$table-accent-bg: color.$gray;
$table-hover-bg: sass-color.adjust(color.$primary, $lightness: 40%);

// Font configuration
$font-family-base: 'Proxima Nova';
$font-size-base: 1rem;
$font-size-lg: $font-size-base * 1.25;
$font-size-sm: $font-size-base * .8;

// Button configuration
$btn-font-weight: 700;
$btn-padding-x: 11px;
$btn-padding-y: 5px;
$btn-padding-x-sm: 7px;
$btn-padding-y-sm: 3px;
$input-btn-focus-box-shadow: 0px 0px 0px 3.2px rgba(0, 123, 255, 0.25);

// Input configuration
$input-btn-padding-y: .5rem;
$input-padding-x: .5rem;
$input-padding-y: .5rem;
$input-disabled-bg: #E9ECEF;
$input-placeholder-color: color.$gray-3;
$input-height: 40px;
$input-height-sm: 36px;
$input-font-size: 1rem;
$input-font-size-sm: 13px;

// Container configuration
$xs: 360px;
$sm: 768px;
$md: 1200px;
$lg: 1400px;
$xl: 1900px;
