@use 'variables/_color' as color;
@use 'bootstrap' as bootstrap;

input[bsdatepicker] {
  width: 200px !important;

  &.full-width {
    width: 100% !important;
  }

  &.with-datepicker-clear-button {
    padding-right: 40px !important;
  }

  &.with-placeholder-as-value {
    &::placeholder {
      color: color.$gray !important;
    }
  }

  & + div.datepicker-clear-button-wrapper {
    position: relative;
    align-self: flex-start;
    line-height: 21.6px;

    button.datepicker-clear-button {
      position: absolute;
      right: 6px;
      top: 4px !important;
      font-size: 18px !important;
      line-height: 18px !important;
      padding-left: 8px !important;
      padding-right: 8px !important;
      padding-top: 4px !important;
      padding-bottom: 3px !important;
    }
  }

  & ~ div.datepicker-clear-button-wrapper.standalone {
    margin-left: 10px;

    button.datepicker-clear-button {
      font-size: 20px !important;
      line-height: 20px !important;
      height: calc(100% - 1px);
    }
  }
}

.bs-datepicker {
  &.theme-custom {
    .bs-datepicker-head {
      background-color: color.$primary;
    }

    .bs-datepicker-body {
      table {
        td span.selected,
        td.selected span,
        td span[class*="select-"]:after,
        td[class*="select-"] span:after {
          background-color: color.$primary;
        }

        td.week span {
          color: color.$primary;
        }
      }
    }
  }
}
