@use "variables/color";
@use "variables/breakpoint";

$separator-color: color.$gray;

.page {
  .header-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    padding-bottom: 2rem;

    min-height: 70px;

    div.header {
      font-weight: 400;
      font-size: 1.25rem;
      line-height: 28px;

      padding-right: .5rem;
    }

    button.back {
      display: flex;
      align-items: center;
      justify-content: center;

      outline: 0;
      border: 0;
      padding: 0;

      background-color: transparent;

      font-weight: 400;
      font-size: 1.25rem;
      line-height: 28px;

      i-tabler {
        display: flex;

        width: 30px;
        height: 30px;
      }

      &:hover, &:focus-visible {
        color: color.$primary;
      }
    }

    .actions {
      display: flex;
      gap: 1rem;
      margin-top: 1rem;

      flex-wrap: wrap;
      justify-content: end;
    }

    app-page-action-container:has(> div:empty) {
      display: none;
    }

    &.separator {
      border-bottom: 1px solid $separator-color;
    }
  }

  .header-container.separator ~ .content {
    padding-top: 2rem;
  }
}

@media(min-width: breakpoint.$sm) {
  .page {
    .header-container {
      align-items: center;
      justify-content: space-between;
      flex-direction: row;

      .actions, app-page-action-container > #page-action-container {
        margin-top: 0;
      }
    }
  }
}