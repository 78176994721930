body {
  font-size: 16px;

  &.layout-pre {
    overflow-y: hidden;
  }

  &.layout-main {
    overflow-y: scroll;

    &.modal-open {
      overflow-y: scroll !important;
      padding-right: 0 !important;
    }
  }
}

.stop-scrolling {
  height: 100%;
  overflow: hidden !important;
}

.min-height-100-dvh {
  min-height: 100dvh;
}
