@use 'variables/_color' as color;
@use 'bootstrap' as bootstrap;

$grey: #DEE2E6;

tabset.tab-container {
  .nav-tabs {
    a {
      color: color.$black;
    }

    li, a {
      &:focus-visible {
        outline: 0;
      }
    }

    border-bottom-color: $grey;
  }

  .nav-tabs .nav-link.disabled {
    color: color.$gray-3;
  }

  .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: color.$primary;
    background-color: transparent;
    border-color: $grey $grey white;
  }


  .nav-pills {
    a {
      color: color.$green;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 14px;
    }

    li, a {
      &:focus-visible {
        color: color.$green-2;
        outline: 0;
      }
    }
  }

  .nav-pills .nav-link.active, .nav-pills .nav-item.show .nav-link {
    color: color.$white;
    background-color: color.$green-2;
  }
}
