@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 100;
  src: url("./../asset/font/proximaNova/ProximaNovaThin.otf") format("opentype");
}

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 300;
  src: url("./../asset/font/proximaNova/ProximaNovaLight.otf") format("opentype");
}

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  src: url("./../asset/font/proximaNova/ProximaNovaRegular.otf") format("opentype");
}

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 500;
  src: url("./../asset/font/proximaNova/ProximaNovaSemibold.otf") format("opentype");
}

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 700;
  src: url("./../asset/font/proximaNova/ProximaNovaBold.otf") format("opentype");
}

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 800;
  src: url("./../asset/font/proximaNova/ProximaNovaExtrabold.otf") format("opentype");
}
