@use 'variables/_color' as color;
@use 'bootstrap' as bootstrap;

table.table {
  &:not(.table-borderless) {
    border: 1px solid bootstrap.$gray-300 !important;
  }

  &.table-ellipsis-wrap {
    td {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 0;
    }
  }

  td.control {
    padding: 0.5rem !important;
  }
}
