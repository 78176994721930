@use 'variables/_color' as color;
@use 'bootstrap' as bootstrap;
@use 'sass:color' as sass-color;

.dropdown-menu {
  box-shadow: bootstrap.$dropdown-box-shadow;
  border: none;

  li[role="menuitem"].dropdown-item-no-actions {
    display: none;

    &:only-child {
      display: list-item;
    }
  }


  a.dropdown-item {
    font-size: .875rem;
    font-weight: 700;

    padding-left: 24px;
    padding-right: 24px;

    cursor: pointer;

    &:active {
      color: inherit;
    }

    &:hover, &:focus-visible {
      background-color: rgba(color.$gray, .4);
      outline: 0;
    }
  }
}
