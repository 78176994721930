@charset "UTF-8";
/*!
 * Bootstrap v4.6.1 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #2EA39D;
  --secondary: #0D448C;
  --success: #2EA39D;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #D82E1F;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 768px;
  --breakpoint-md: 1200px;
  --breakpoint-lg: 1400px;
  --breakpoint-xl: 1900px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Proxima Nova";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #002A41;
  text-align: left;
  background-color: #F2F2F2;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #0D448C;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #072246;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #F2F2F2;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm,
.container-xs {
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto;
}

.container {
  max-width: 360px;
}

@media (min-width: 768px) {
  .container-sm, .container {
    max-width: 768px;
  }
}
@media (min-width: 1200px) {
  .container-md, .container-sm, .container {
    max-width: 1200px;
  }
}
@media (min-width: 1400px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 1400px;
  }
}
@media (min-width: 1900px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1900px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 768px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1400px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1900px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #002A41;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #F2F2F2;
}

.table-hover tbody tr:hover {
  color: #002A41;
  background-color: #b3eae7;
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c4e5e4;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #92cfcc;
}

.table-hover .table-primary:hover {
  background-color: #b2dddc;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #b2dddc;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #bbcbdf;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #819ec3;
}

.table-hover .table-secondary:hover {
  background-color: #aabed7;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #aabed7;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c4e5e4;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #92cfcc;
}

.table-hover .table-success:hover {
  background-color: #b2dddc;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b2dddc;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f4c4c0;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #eb928b;
}

.table-hover .table-danger:hover {
  background-color: #f0b0aa;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f0b0aa;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: #b3eae7;
}

.table-hover .table-active:hover {
  background-color: #9fe4e1;
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: #9fe4e1;
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 767.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1899.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: 40px;
  padding: 0.5rem 0.5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #78d9d4;
  outline: 0;
  box-shadow: 0px 0px 0px 3.2px rgba(0, 123, 255, 0.25);
}
.form-control::placeholder {
  color: #999999;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #E9ECEF;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.8125rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.5rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #002A41;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: 36px;
  padding: 0.25rem 0.5rem;
  font-size: 0.8125rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #2EA39D;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.8rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(46, 163, 157, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #2EA39D;
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #2EA39D;
  box-shadow: 0 0 0 0.2rem rgba(46, 163, 157, 0.25);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #2EA39D;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #2EA39D;
  box-shadow: 0 0 0 0.2rem rgba(46, 163, 157, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #2EA39D;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #2EA39D;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #2EA39D;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #3cc8c1;
  background-color: #3cc8c1;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(46, 163, 157, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #2EA39D;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #2EA39D;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #2EA39D;
  box-shadow: 0 0 0 0.2rem rgba(46, 163, 157, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #D82E1F;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.8rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(216, 46, 31, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #D82E1F;
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #D82E1F;
  box-shadow: 0 0 0 0.2rem rgba(216, 46, 31, 0.25);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #D82E1F;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #D82E1F;
  box-shadow: 0 0 0 0.2rem rgba(216, 46, 31, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #D82E1F;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #D82E1F;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #D82E1F;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e45246;
  background-color: #e45246;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(216, 46, 31, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #D82E1F;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #D82E1F;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #D82E1F;
  box-shadow: 0 0 0 0.2rem rgba(216, 46, 31, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 768px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 700;
  color: #002A41;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 5px 11px;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #002A41;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0px 0px 0px 3.2px rgba(0, 123, 255, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #2EA39D;
  border-color: #2EA39D;
}
.btn-primary:hover {
  color: #fff;
  background-color: #268580;
  border-color: #237b77;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #268580;
  border-color: #237b77;
  box-shadow: 0 0 0 0.2rem rgba(77, 177, 172, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #2EA39D;
  border-color: #2EA39D;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #237b77;
  border-color: #20716d;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(77, 177, 172, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #0D448C;
  border-color: #0D448C;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #0a3369;
  border-color: #092d5d;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #0a3369;
  border-color: #092d5d;
  box-shadow: 0 0 0 0.2rem rgba(49, 96, 157, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #0D448C;
  border-color: #0D448C;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #092d5d;
  border-color: #082852;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(49, 96, 157, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #2EA39D;
  border-color: #2EA39D;
}
.btn-success:hover {
  color: #fff;
  background-color: #268580;
  border-color: #237b77;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #268580;
  border-color: #237b77;
  box-shadow: 0 0 0 0.2rem rgba(77, 177, 172, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #2EA39D;
  border-color: #2EA39D;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #237b77;
  border-color: #20716d;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(77, 177, 172, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #D82E1F;
  border-color: #D82E1F;
}
.btn-danger:hover {
  color: #fff;
  background-color: #b7271a;
  border-color: #ab2519;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #b7271a;
  border-color: #ab2519;
  box-shadow: 0 0 0 0.2rem rgba(222, 77, 65, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #D82E1F;
  border-color: #D82E1F;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #ab2519;
  border-color: #a02217;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 77, 65, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #2EA39D;
  border-color: #2EA39D;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #2EA39D;
  border-color: #2EA39D;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(46, 163, 157, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #2EA39D;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #2EA39D;
  border-color: #2EA39D;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(46, 163, 157, 0.5);
}

.btn-outline-secondary {
  color: #0D448C;
  border-color: #0D448C;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #0D448C;
  border-color: #0D448C;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(13, 68, 140, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #0D448C;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #0D448C;
  border-color: #0D448C;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(13, 68, 140, 0.5);
}

.btn-outline-success {
  color: #2EA39D;
  border-color: #2EA39D;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #2EA39D;
  border-color: #2EA39D;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(46, 163, 157, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #2EA39D;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #2EA39D;
  border-color: #2EA39D;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(46, 163, 157, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #D82E1F;
  border-color: #D82E1F;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #D82E1F;
  border-color: #D82E1F;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 46, 31, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #D82E1F;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #D82E1F;
  border-color: #D82E1F;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 46, 31, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #0D448C;
  text-decoration: none;
}
.btn-link:hover {
  color: #072246;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 3px 7px;
  font-size: 0.8rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #002A41;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 768px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1900px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: white;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #2EA39D;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.8rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 8.25px;
  padding-left: 8.25px;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 5.25px;
  padding-left: 5.25px;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label,
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.5rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: 36px;
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.8125rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.5rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #002A41;
  background-color: #002A41;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #002A41;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #FFFFFF;
  border-color: #002A41;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #999999;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #E9ECEF;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 2px;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #2EA39D;
  background-color: #2EA39D;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #999999;
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: #999999;
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #999999;
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #999999;
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: 40px;
  padding: 0.5rem 1.5rem 0.5rem 0.5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.5rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #78d9d4;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(46, 163, 157, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.5rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: 36px;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.8125rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 40px;
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 40px;
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #78d9d4;
  box-shadow: 0px 0px 0px 3.2px rgba(0, 123, 255, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #E9ECEF;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: 40px;
  padding: 0.5rem 0.5rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 1rem);
  padding: 0.5rem 0.5rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #F2F2F2, 0px 0px 0px 3.2px rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #F2F2F2, 0px 0px 0px 3.2px rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #F2F2F2, 0px 0px 0px 3.2px rgba(0, 123, 255, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #2EA39D;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #9fe4e1;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #2EA39D;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #9fe4e1;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #2EA39D;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #9fe4e1;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #F2F2F2;
  border-color: #dee2e6 #dee2e6 #F2F2F2;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #2EA39D;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-xs,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 767.98px) {
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-xs,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-xs,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-xs,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-xs,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1399.98px) {
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-xs,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-xs,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1899.98px) {
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-xs,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1900px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-xs,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-xs,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-xs,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 12px;
}
@media (min-width: 768px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -12px;
    margin-left: -12px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 12px;
    margin-bottom: 0;
    margin-left: 12px;
  }
}

.card-group > .card {
  margin-bottom: 12px;
}
@media (min-width: 768px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 768px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #0D448C;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #072246;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0px 0px 0px 3.2px rgba(0, 123, 255, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #2EA39D;
  border-color: #2EA39D;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.8rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #2EA39D;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #237b77;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(46, 163, 157, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #0D448C;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #092d5d;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(13, 68, 140, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #2EA39D;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #237b77;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(46, 163, 157, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #D82E1F;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #ab2519;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(216, 46, 31, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 768px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #185552;
  background-color: #d5edeb;
  border-color: #c4e5e4;
}
.alert-primary hr {
  border-top-color: #b2dddc;
}
.alert-primary .alert-link {
  color: #0d2d2c;
}

.alert-secondary {
  color: #072349;
  background-color: #cfdae8;
  border-color: #bbcbdf;
}
.alert-secondary hr {
  border-top-color: #aabed7;
}
.alert-secondary .alert-link {
  color: #030d1a;
}

.alert-success {
  color: #185552;
  background-color: #d5edeb;
  border-color: #c4e5e4;
}
.alert-success hr {
  border-top-color: #b2dddc;
}
.alert-success .alert-link {
  color: #0d2d2c;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #701810;
  background-color: #f7d5d2;
  border-color: #f4c4c0;
}
.alert-danger hr {
  border-top-color: #f0b0aa;
}
.alert-danger .alert-link {
  color: #430e0a;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #2EA39D;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #002A41;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #2EA39D;
  border-color: #2EA39D;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 768px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1900px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #185552;
  background-color: #c4e5e4;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #185552;
  background-color: #b2dddc;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #185552;
  border-color: #185552;
}

.list-group-item-secondary {
  color: #072349;
  background-color: #bbcbdf;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #072349;
  background-color: #aabed7;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #072349;
  border-color: #072349;
}

.list-group-item-success {
  color: #185552;
  background-color: #c4e5e4;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #185552;
  background-color: #b2dddc;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #185552;
  border-color: #185552;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #701810;
  background-color: #f4c4c0;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #701810;
  background-color: #f0b0aa;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #701810;
  border-color: #701810;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 768px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 1400px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1900px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.8rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.8rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #002A41;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    animation-duration: 1.5s;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #2EA39D !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #237b77 !important;
}

.bg-secondary {
  background-color: #0D448C !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #092d5d !important;
}

.bg-success {
  background-color: #2EA39D !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #237b77 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #D82E1F !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ab2519 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #2EA39D !important;
}

.border-secondary {
  border-color: #0D448C !important;
}

.border-success {
  border-color: #2EA39D !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #D82E1F !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 768px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1400px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1900px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 768px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1400px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1900px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 768px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 1400px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1900px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.5rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.5rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.5rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.5rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.5rem !important;
}

.m-2 {
  margin: 1rem !important;
}

.mt-2,
.my-2 {
  margin-top: 1rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 1rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 1rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 1rem !important;
}

.m-3 {
  margin: 1.5rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1.5rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1.5rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1.5rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1.5rem !important;
}

.m-4 {
  margin: 2rem !important;
}

.mt-4,
.my-4 {
  margin-top: 2rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 2rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 2rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 2rem !important;
}

.m-5 {
  margin: 2.5rem !important;
}

.mt-5,
.my-5 {
  margin-top: 2.5rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 2.5rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 2.5rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 2.5rem !important;
}

.m-6 {
  margin: 3rem !important;
}

.mt-6,
.my-6 {
  margin-top: 3rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 3rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 3rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 3rem !important;
}

.m-7 {
  margin: 3.5rem !important;
}

.mt-7,
.my-7 {
  margin-top: 3.5rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 3.5rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 3.5rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 3.5rem !important;
}

.m-8 {
  margin: 4rem !important;
}

.mt-8,
.my-8 {
  margin-top: 4rem !important;
}

.mr-8,
.mx-8 {
  margin-right: 4rem !important;
}

.mb-8,
.my-8 {
  margin-bottom: 4rem !important;
}

.ml-8,
.mx-8 {
  margin-left: 4rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.5rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.5rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.5rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.5rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.5rem !important;
}

.p-2 {
  padding: 1rem !important;
}

.pt-2,
.py-2 {
  padding-top: 1rem !important;
}

.pr-2,
.px-2 {
  padding-right: 1rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 1rem !important;
}

.pl-2,
.px-2 {
  padding-left: 1rem !important;
}

.p-3 {
  padding: 1.5rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1.5rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1.5rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1.5rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1.5rem !important;
}

.p-4 {
  padding: 2rem !important;
}

.pt-4,
.py-4 {
  padding-top: 2rem !important;
}

.pr-4,
.px-4 {
  padding-right: 2rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 2rem !important;
}

.pl-4,
.px-4 {
  padding-left: 2rem !important;
}

.p-5 {
  padding: 2.5rem !important;
}

.pt-5,
.py-5 {
  padding-top: 2.5rem !important;
}

.pr-5,
.px-5 {
  padding-right: 2.5rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 2.5rem !important;
}

.pl-5,
.px-5 {
  padding-left: 2.5rem !important;
}

.p-6 {
  padding: 3rem !important;
}

.pt-6,
.py-6 {
  padding-top: 3rem !important;
}

.pr-6,
.px-6 {
  padding-right: 3rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 3rem !important;
}

.pl-6,
.px-6 {
  padding-left: 3rem !important;
}

.p-7 {
  padding: 3.5rem !important;
}

.pt-7,
.py-7 {
  padding-top: 3.5rem !important;
}

.pr-7,
.px-7 {
  padding-right: 3.5rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 3.5rem !important;
}

.pl-7,
.px-7 {
  padding-left: 3.5rem !important;
}

.p-8 {
  padding: 4rem !important;
}

.pt-8,
.py-8 {
  padding-top: 4rem !important;
}

.pr-8,
.px-8 {
  padding-right: 4rem !important;
}

.pb-8,
.py-8 {
  padding-bottom: 4rem !important;
}

.pl-8,
.px-8 {
  padding-left: 4rem !important;
}

.m-n1 {
  margin: -0.5rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.5rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.5rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.5rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.5rem !important;
}

.m-n2 {
  margin: -1rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -1rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -1rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -1rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -1rem !important;
}

.m-n3 {
  margin: -1.5rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1.5rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1.5rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1.5rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1.5rem !important;
}

.m-n4 {
  margin: -2rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -2rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -2rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -2rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -2rem !important;
}

.m-n5 {
  margin: -2.5rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -2.5rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -2.5rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -2.5rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -2.5rem !important;
}

.m-n6 {
  margin: -3rem !important;
}

.mt-n6,
.my-n6 {
  margin-top: -3rem !important;
}

.mr-n6,
.mx-n6 {
  margin-right: -3rem !important;
}

.mb-n6,
.my-n6 {
  margin-bottom: -3rem !important;
}

.ml-n6,
.mx-n6 {
  margin-left: -3rem !important;
}

.m-n7 {
  margin: -3.5rem !important;
}

.mt-n7,
.my-n7 {
  margin-top: -3.5rem !important;
}

.mr-n7,
.mx-n7 {
  margin-right: -3.5rem !important;
}

.mb-n7,
.my-n7 {
  margin-bottom: -3.5rem !important;
}

.ml-n7,
.mx-n7 {
  margin-left: -3.5rem !important;
}

.m-n8 {
  margin: -4rem !important;
}

.mt-n8,
.my-n8 {
  margin-top: -4rem !important;
}

.mr-n8,
.mx-n8 {
  margin-right: -4rem !important;
}

.mb-n8,
.my-n8 {
  margin-bottom: -4rem !important;
}

.ml-n8,
.mx-n8 {
  margin-left: -4rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 768px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.5rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.5rem !important;
  }

  .m-sm-2 {
    margin: 1rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 1rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 1rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 1rem !important;
  }

  .m-sm-3 {
    margin: 1.5rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1.5rem !important;
  }

  .m-sm-4 {
    margin: 2rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 2rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 2rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 2rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 2rem !important;
  }

  .m-sm-5 {
    margin: 2.5rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 2.5rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 2.5rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 2.5rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 2.5rem !important;
  }

  .m-sm-6 {
    margin: 3rem !important;
  }

  .mt-sm-6,
.my-sm-6 {
    margin-top: 3rem !important;
  }

  .mr-sm-6,
.mx-sm-6 {
    margin-right: 3rem !important;
  }

  .mb-sm-6,
.my-sm-6 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-6,
.mx-sm-6 {
    margin-left: 3rem !important;
  }

  .m-sm-7 {
    margin: 3.5rem !important;
  }

  .mt-sm-7,
.my-sm-7 {
    margin-top: 3.5rem !important;
  }

  .mr-sm-7,
.mx-sm-7 {
    margin-right: 3.5rem !important;
  }

  .mb-sm-7,
.my-sm-7 {
    margin-bottom: 3.5rem !important;
  }

  .ml-sm-7,
.mx-sm-7 {
    margin-left: 3.5rem !important;
  }

  .m-sm-8 {
    margin: 4rem !important;
  }

  .mt-sm-8,
.my-sm-8 {
    margin-top: 4rem !important;
  }

  .mr-sm-8,
.mx-sm-8 {
    margin-right: 4rem !important;
  }

  .mb-sm-8,
.my-sm-8 {
    margin-bottom: 4rem !important;
  }

  .ml-sm-8,
.mx-sm-8 {
    margin-left: 4rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.5rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.5rem !important;
  }

  .p-sm-2 {
    padding: 1rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 1rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 1rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 1rem !important;
  }

  .p-sm-3 {
    padding: 1.5rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1.5rem !important;
  }

  .p-sm-4 {
    padding: 2rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 2rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 2rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 2rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 2rem !important;
  }

  .p-sm-5 {
    padding: 2.5rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 2.5rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 2.5rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 2.5rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 2.5rem !important;
  }

  .p-sm-6 {
    padding: 3rem !important;
  }

  .pt-sm-6,
.py-sm-6 {
    padding-top: 3rem !important;
  }

  .pr-sm-6,
.px-sm-6 {
    padding-right: 3rem !important;
  }

  .pb-sm-6,
.py-sm-6 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-6,
.px-sm-6 {
    padding-left: 3rem !important;
  }

  .p-sm-7 {
    padding: 3.5rem !important;
  }

  .pt-sm-7,
.py-sm-7 {
    padding-top: 3.5rem !important;
  }

  .pr-sm-7,
.px-sm-7 {
    padding-right: 3.5rem !important;
  }

  .pb-sm-7,
.py-sm-7 {
    padding-bottom: 3.5rem !important;
  }

  .pl-sm-7,
.px-sm-7 {
    padding-left: 3.5rem !important;
  }

  .p-sm-8 {
    padding: 4rem !important;
  }

  .pt-sm-8,
.py-sm-8 {
    padding-top: 4rem !important;
  }

  .pr-sm-8,
.px-sm-8 {
    padding-right: 4rem !important;
  }

  .pb-sm-8,
.py-sm-8 {
    padding-bottom: 4rem !important;
  }

  .pl-sm-8,
.px-sm-8 {
    padding-left: 4rem !important;
  }

  .m-sm-n1 {
    margin: -0.5rem !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n2 {
    margin: -1rem !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -1rem !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -1rem !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -1rem !important;
  }

  .m-sm-n3 {
    margin: -1.5rem !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n4 {
    margin: -2rem !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -2rem !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -2rem !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -2rem !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -2rem !important;
  }

  .m-sm-n5 {
    margin: -2.5rem !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -2.5rem !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -2.5rem !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -2.5rem !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -2.5rem !important;
  }

  .m-sm-n6 {
    margin: -3rem !important;
  }

  .mt-sm-n6,
.my-sm-n6 {
    margin-top: -3rem !important;
  }

  .mr-sm-n6,
.mx-sm-n6 {
    margin-right: -3rem !important;
  }

  .mb-sm-n6,
.my-sm-n6 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n6,
.mx-sm-n6 {
    margin-left: -3rem !important;
  }

  .m-sm-n7 {
    margin: -3.5rem !important;
  }

  .mt-sm-n7,
.my-sm-n7 {
    margin-top: -3.5rem !important;
  }

  .mr-sm-n7,
.mx-sm-n7 {
    margin-right: -3.5rem !important;
  }

  .mb-sm-n7,
.my-sm-n7 {
    margin-bottom: -3.5rem !important;
  }

  .ml-sm-n7,
.mx-sm-n7 {
    margin-left: -3.5rem !important;
  }

  .m-sm-n8 {
    margin: -4rem !important;
  }

  .mt-sm-n8,
.my-sm-n8 {
    margin-top: -4rem !important;
  }

  .mr-sm-n8,
.mx-sm-n8 {
    margin-right: -4rem !important;
  }

  .mb-sm-n8,
.my-sm-n8 {
    margin-bottom: -4rem !important;
  }

  .ml-sm-n8,
.mx-sm-n8 {
    margin-left: -4rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.5rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.5rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.5rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.5rem !important;
  }

  .m-md-2 {
    margin: 1rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 1rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 1rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 1rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 1rem !important;
  }

  .m-md-3 {
    margin: 1.5rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1.5rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1.5rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1.5rem !important;
  }

  .m-md-4 {
    margin: 2rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 2rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 2rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 2rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 2rem !important;
  }

  .m-md-5 {
    margin: 2.5rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 2.5rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 2.5rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 2.5rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 2.5rem !important;
  }

  .m-md-6 {
    margin: 3rem !important;
  }

  .mt-md-6,
.my-md-6 {
    margin-top: 3rem !important;
  }

  .mr-md-6,
.mx-md-6 {
    margin-right: 3rem !important;
  }

  .mb-md-6,
.my-md-6 {
    margin-bottom: 3rem !important;
  }

  .ml-md-6,
.mx-md-6 {
    margin-left: 3rem !important;
  }

  .m-md-7 {
    margin: 3.5rem !important;
  }

  .mt-md-7,
.my-md-7 {
    margin-top: 3.5rem !important;
  }

  .mr-md-7,
.mx-md-7 {
    margin-right: 3.5rem !important;
  }

  .mb-md-7,
.my-md-7 {
    margin-bottom: 3.5rem !important;
  }

  .ml-md-7,
.mx-md-7 {
    margin-left: 3.5rem !important;
  }

  .m-md-8 {
    margin: 4rem !important;
  }

  .mt-md-8,
.my-md-8 {
    margin-top: 4rem !important;
  }

  .mr-md-8,
.mx-md-8 {
    margin-right: 4rem !important;
  }

  .mb-md-8,
.my-md-8 {
    margin-bottom: 4rem !important;
  }

  .ml-md-8,
.mx-md-8 {
    margin-left: 4rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.5rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.5rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.5rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.5rem !important;
  }

  .p-md-2 {
    padding: 1rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 1rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 1rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 1rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 1rem !important;
  }

  .p-md-3 {
    padding: 1.5rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1.5rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1.5rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1.5rem !important;
  }

  .p-md-4 {
    padding: 2rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 2rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 2rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 2rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 2rem !important;
  }

  .p-md-5 {
    padding: 2.5rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 2.5rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 2.5rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 2.5rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 2.5rem !important;
  }

  .p-md-6 {
    padding: 3rem !important;
  }

  .pt-md-6,
.py-md-6 {
    padding-top: 3rem !important;
  }

  .pr-md-6,
.px-md-6 {
    padding-right: 3rem !important;
  }

  .pb-md-6,
.py-md-6 {
    padding-bottom: 3rem !important;
  }

  .pl-md-6,
.px-md-6 {
    padding-left: 3rem !important;
  }

  .p-md-7 {
    padding: 3.5rem !important;
  }

  .pt-md-7,
.py-md-7 {
    padding-top: 3.5rem !important;
  }

  .pr-md-7,
.px-md-7 {
    padding-right: 3.5rem !important;
  }

  .pb-md-7,
.py-md-7 {
    padding-bottom: 3.5rem !important;
  }

  .pl-md-7,
.px-md-7 {
    padding-left: 3.5rem !important;
  }

  .p-md-8 {
    padding: 4rem !important;
  }

  .pt-md-8,
.py-md-8 {
    padding-top: 4rem !important;
  }

  .pr-md-8,
.px-md-8 {
    padding-right: 4rem !important;
  }

  .pb-md-8,
.py-md-8 {
    padding-bottom: 4rem !important;
  }

  .pl-md-8,
.px-md-8 {
    padding-left: 4rem !important;
  }

  .m-md-n1 {
    margin: -0.5rem !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.5rem !important;
  }

  .m-md-n2 {
    margin: -1rem !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -1rem !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -1rem !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -1rem !important;
  }

  .m-md-n3 {
    margin: -1.5rem !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1.5rem !important;
  }

  .m-md-n4 {
    margin: -2rem !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -2rem !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -2rem !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -2rem !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -2rem !important;
  }

  .m-md-n5 {
    margin: -2.5rem !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -2.5rem !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -2.5rem !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -2.5rem !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -2.5rem !important;
  }

  .m-md-n6 {
    margin: -3rem !important;
  }

  .mt-md-n6,
.my-md-n6 {
    margin-top: -3rem !important;
  }

  .mr-md-n6,
.mx-md-n6 {
    margin-right: -3rem !important;
  }

  .mb-md-n6,
.my-md-n6 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n6,
.mx-md-n6 {
    margin-left: -3rem !important;
  }

  .m-md-n7 {
    margin: -3.5rem !important;
  }

  .mt-md-n7,
.my-md-n7 {
    margin-top: -3.5rem !important;
  }

  .mr-md-n7,
.mx-md-n7 {
    margin-right: -3.5rem !important;
  }

  .mb-md-n7,
.my-md-n7 {
    margin-bottom: -3.5rem !important;
  }

  .ml-md-n7,
.mx-md-n7 {
    margin-left: -3.5rem !important;
  }

  .m-md-n8 {
    margin: -4rem !important;
  }

  .mt-md-n8,
.my-md-n8 {
    margin-top: -4rem !important;
  }

  .mr-md-n8,
.mx-md-n8 {
    margin-right: -4rem !important;
  }

  .mb-md-n8,
.my-md-n8 {
    margin-bottom: -4rem !important;
  }

  .ml-md-n8,
.mx-md-n8 {
    margin-left: -4rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1400px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.5rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.5rem !important;
  }

  .m-lg-2 {
    margin: 1rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 1rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 1rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 1rem !important;
  }

  .m-lg-3 {
    margin: 1.5rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1.5rem !important;
  }

  .m-lg-4 {
    margin: 2rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 2rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 2rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 2rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 2rem !important;
  }

  .m-lg-5 {
    margin: 2.5rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 2.5rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 2.5rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 2.5rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 2.5rem !important;
  }

  .m-lg-6 {
    margin: 3rem !important;
  }

  .mt-lg-6,
.my-lg-6 {
    margin-top: 3rem !important;
  }

  .mr-lg-6,
.mx-lg-6 {
    margin-right: 3rem !important;
  }

  .mb-lg-6,
.my-lg-6 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-6,
.mx-lg-6 {
    margin-left: 3rem !important;
  }

  .m-lg-7 {
    margin: 3.5rem !important;
  }

  .mt-lg-7,
.my-lg-7 {
    margin-top: 3.5rem !important;
  }

  .mr-lg-7,
.mx-lg-7 {
    margin-right: 3.5rem !important;
  }

  .mb-lg-7,
.my-lg-7 {
    margin-bottom: 3.5rem !important;
  }

  .ml-lg-7,
.mx-lg-7 {
    margin-left: 3.5rem !important;
  }

  .m-lg-8 {
    margin: 4rem !important;
  }

  .mt-lg-8,
.my-lg-8 {
    margin-top: 4rem !important;
  }

  .mr-lg-8,
.mx-lg-8 {
    margin-right: 4rem !important;
  }

  .mb-lg-8,
.my-lg-8 {
    margin-bottom: 4rem !important;
  }

  .ml-lg-8,
.mx-lg-8 {
    margin-left: 4rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.5rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.5rem !important;
  }

  .p-lg-2 {
    padding: 1rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 1rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 1rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 1rem !important;
  }

  .p-lg-3 {
    padding: 1.5rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1.5rem !important;
  }

  .p-lg-4 {
    padding: 2rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 2rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 2rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 2rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 2rem !important;
  }

  .p-lg-5 {
    padding: 2.5rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 2.5rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 2.5rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 2.5rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 2.5rem !important;
  }

  .p-lg-6 {
    padding: 3rem !important;
  }

  .pt-lg-6,
.py-lg-6 {
    padding-top: 3rem !important;
  }

  .pr-lg-6,
.px-lg-6 {
    padding-right: 3rem !important;
  }

  .pb-lg-6,
.py-lg-6 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-6,
.px-lg-6 {
    padding-left: 3rem !important;
  }

  .p-lg-7 {
    padding: 3.5rem !important;
  }

  .pt-lg-7,
.py-lg-7 {
    padding-top: 3.5rem !important;
  }

  .pr-lg-7,
.px-lg-7 {
    padding-right: 3.5rem !important;
  }

  .pb-lg-7,
.py-lg-7 {
    padding-bottom: 3.5rem !important;
  }

  .pl-lg-7,
.px-lg-7 {
    padding-left: 3.5rem !important;
  }

  .p-lg-8 {
    padding: 4rem !important;
  }

  .pt-lg-8,
.py-lg-8 {
    padding-top: 4rem !important;
  }

  .pr-lg-8,
.px-lg-8 {
    padding-right: 4rem !important;
  }

  .pb-lg-8,
.py-lg-8 {
    padding-bottom: 4rem !important;
  }

  .pl-lg-8,
.px-lg-8 {
    padding-left: 4rem !important;
  }

  .m-lg-n1 {
    margin: -0.5rem !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n2 {
    margin: -1rem !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -1rem !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -1rem !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -1rem !important;
  }

  .m-lg-n3 {
    margin: -1.5rem !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n4 {
    margin: -2rem !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -2rem !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -2rem !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -2rem !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -2rem !important;
  }

  .m-lg-n5 {
    margin: -2.5rem !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -2.5rem !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -2.5rem !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -2.5rem !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -2.5rem !important;
  }

  .m-lg-n6 {
    margin: -3rem !important;
  }

  .mt-lg-n6,
.my-lg-n6 {
    margin-top: -3rem !important;
  }

  .mr-lg-n6,
.mx-lg-n6 {
    margin-right: -3rem !important;
  }

  .mb-lg-n6,
.my-lg-n6 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n6,
.mx-lg-n6 {
    margin-left: -3rem !important;
  }

  .m-lg-n7 {
    margin: -3.5rem !important;
  }

  .mt-lg-n7,
.my-lg-n7 {
    margin-top: -3.5rem !important;
  }

  .mr-lg-n7,
.mx-lg-n7 {
    margin-right: -3.5rem !important;
  }

  .mb-lg-n7,
.my-lg-n7 {
    margin-bottom: -3.5rem !important;
  }

  .ml-lg-n7,
.mx-lg-n7 {
    margin-left: -3.5rem !important;
  }

  .m-lg-n8 {
    margin: -4rem !important;
  }

  .mt-lg-n8,
.my-lg-n8 {
    margin-top: -4rem !important;
  }

  .mr-lg-n8,
.mx-lg-n8 {
    margin-right: -4rem !important;
  }

  .mb-lg-n8,
.my-lg-n8 {
    margin-bottom: -4rem !important;
  }

  .ml-lg-n8,
.mx-lg-n8 {
    margin-left: -4rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1900px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.5rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.5rem !important;
  }

  .m-xl-2 {
    margin: 1rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 1rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 1rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 1rem !important;
  }

  .m-xl-3 {
    margin: 1.5rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1.5rem !important;
  }

  .m-xl-4 {
    margin: 2rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 2rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 2rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 2rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 2rem !important;
  }

  .m-xl-5 {
    margin: 2.5rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 2.5rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 2.5rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 2.5rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 2.5rem !important;
  }

  .m-xl-6 {
    margin: 3rem !important;
  }

  .mt-xl-6,
.my-xl-6 {
    margin-top: 3rem !important;
  }

  .mr-xl-6,
.mx-xl-6 {
    margin-right: 3rem !important;
  }

  .mb-xl-6,
.my-xl-6 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-6,
.mx-xl-6 {
    margin-left: 3rem !important;
  }

  .m-xl-7 {
    margin: 3.5rem !important;
  }

  .mt-xl-7,
.my-xl-7 {
    margin-top: 3.5rem !important;
  }

  .mr-xl-7,
.mx-xl-7 {
    margin-right: 3.5rem !important;
  }

  .mb-xl-7,
.my-xl-7 {
    margin-bottom: 3.5rem !important;
  }

  .ml-xl-7,
.mx-xl-7 {
    margin-left: 3.5rem !important;
  }

  .m-xl-8 {
    margin: 4rem !important;
  }

  .mt-xl-8,
.my-xl-8 {
    margin-top: 4rem !important;
  }

  .mr-xl-8,
.mx-xl-8 {
    margin-right: 4rem !important;
  }

  .mb-xl-8,
.my-xl-8 {
    margin-bottom: 4rem !important;
  }

  .ml-xl-8,
.mx-xl-8 {
    margin-left: 4rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.5rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.5rem !important;
  }

  .p-xl-2 {
    padding: 1rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 1rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 1rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 1rem !important;
  }

  .p-xl-3 {
    padding: 1.5rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1.5rem !important;
  }

  .p-xl-4 {
    padding: 2rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 2rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 2rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 2rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 2rem !important;
  }

  .p-xl-5 {
    padding: 2.5rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 2.5rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 2.5rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 2.5rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 2.5rem !important;
  }

  .p-xl-6 {
    padding: 3rem !important;
  }

  .pt-xl-6,
.py-xl-6 {
    padding-top: 3rem !important;
  }

  .pr-xl-6,
.px-xl-6 {
    padding-right: 3rem !important;
  }

  .pb-xl-6,
.py-xl-6 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-6,
.px-xl-6 {
    padding-left: 3rem !important;
  }

  .p-xl-7 {
    padding: 3.5rem !important;
  }

  .pt-xl-7,
.py-xl-7 {
    padding-top: 3.5rem !important;
  }

  .pr-xl-7,
.px-xl-7 {
    padding-right: 3.5rem !important;
  }

  .pb-xl-7,
.py-xl-7 {
    padding-bottom: 3.5rem !important;
  }

  .pl-xl-7,
.px-xl-7 {
    padding-left: 3.5rem !important;
  }

  .p-xl-8 {
    padding: 4rem !important;
  }

  .pt-xl-8,
.py-xl-8 {
    padding-top: 4rem !important;
  }

  .pr-xl-8,
.px-xl-8 {
    padding-right: 4rem !important;
  }

  .pb-xl-8,
.py-xl-8 {
    padding-bottom: 4rem !important;
  }

  .pl-xl-8,
.px-xl-8 {
    padding-left: 4rem !important;
  }

  .m-xl-n1 {
    margin: -0.5rem !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n2 {
    margin: -1rem !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -1rem !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -1rem !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -1rem !important;
  }

  .m-xl-n3 {
    margin: -1.5rem !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n4 {
    margin: -2rem !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -2rem !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -2rem !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -2rem !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -2rem !important;
  }

  .m-xl-n5 {
    margin: -2.5rem !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -2.5rem !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -2.5rem !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -2.5rem !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -2.5rem !important;
  }

  .m-xl-n6 {
    margin: -3rem !important;
  }

  .mt-xl-n6,
.my-xl-n6 {
    margin-top: -3rem !important;
  }

  .mr-xl-n6,
.mx-xl-n6 {
    margin-right: -3rem !important;
  }

  .mb-xl-n6,
.my-xl-n6 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n6,
.mx-xl-n6 {
    margin-left: -3rem !important;
  }

  .m-xl-n7 {
    margin: -3.5rem !important;
  }

  .mt-xl-n7,
.my-xl-n7 {
    margin-top: -3.5rem !important;
  }

  .mr-xl-n7,
.mx-xl-n7 {
    margin-right: -3.5rem !important;
  }

  .mb-xl-n7,
.my-xl-n7 {
    margin-bottom: -3.5rem !important;
  }

  .ml-xl-n7,
.mx-xl-n7 {
    margin-left: -3.5rem !important;
  }

  .m-xl-n8 {
    margin: -4rem !important;
  }

  .mt-xl-n8,
.my-xl-n8 {
    margin-top: -4rem !important;
  }

  .mr-xl-n8,
.mx-xl-n8 {
    margin-right: -4rem !important;
  }

  .mb-xl-n8,
.my-xl-n8 {
    margin-bottom: -4rem !important;
  }

  .ml-xl-n8,
.mx-xl-n8 {
    margin-left: -4rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 768px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1900px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #2EA39D !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #1d6764 !important;
}

.text-secondary {
  color: #0D448C !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #072246 !important;
}

.text-success {
  color: #2EA39D !important;
}

a.text-success:hover, a.text-success:focus {
  color: #1d6764 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #D82E1F !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #952015 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #002A41 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }
  body {
    min-width: 1400px !important;
  }

  .container {
    min-width: 1400px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #fff !important;
  }

  .table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #dee2e6;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
.color-primary {
  color: #2EA39D;
}

.background-color-primary {
  background-color: #2EA39D;
}

.border-color-primary {
  border-color: #2EA39D;
}

.fill-primary {
  fill: #2EA39D;
}

.color-secondary {
  color: #0D448C;
}

.background-color-secondary {
  background-color: #0D448C;
}

.border-color-secondary {
  border-color: #0D448C;
}

.fill-secondary {
  fill: #0D448C;
}

.color-black {
  color: #002A41;
}

.background-color-black {
  background-color: #002A41;
}

.border-color-black {
  border-color: #002A41;
}

.fill-black {
  fill: #002A41;
}

.color-success {
  color: #2EA39D;
}

.background-color-success {
  background-color: #2EA39D;
}

.border-color-success {
  border-color: #2EA39D;
}

.fill-success {
  fill: #2EA39D;
}

.color-danger {
  color: #D82E1F;
}

.background-color-danger {
  background-color: #D82E1F;
}

.border-color-danger {
  border-color: #D82E1F;
}

.fill-danger {
  fill: #D82E1F;
}

.color-blue {
  color: #0D448C;
}

.background-color-blue {
  background-color: #0D448C;
}

.border-color-blue {
  border-color: #0D448C;
}

.fill-blue {
  fill: #0D448C;
}

.color-blue-1 {
  color: #002A41;
}

.background-color-blue-1 {
  background-color: #002A41;
}

.border-color-blue-1 {
  border-color: #002A41;
}

.fill-blue-1 {
  fill: #002A41;
}

.color-green {
  color: #2EA39D;
}

.background-color-green {
  background-color: #2EA39D;
}

.border-color-green {
  border-color: #2EA39D;
}

.fill-green {
  fill: #2EA39D;
}

.color-green-2 {
  color: #35BDB6;
}

.background-color-green-2 {
  background-color: #35BDB6;
}

.border-color-green-2 {
  border-color: #35BDB6;
}

.fill-green-2 {
  fill: #35BDB6;
}

.color-green-3 {
  color: #C9F4F2;
}

.background-color-green-3 {
  background-color: #C9F4F2;
}

.border-color-green-3 {
  border-color: #C9F4F2;
}

.fill-green-3 {
  fill: #C9F4F2;
}

.color-white {
  color: #FFFFFF;
}

.background-color-white {
  background-color: #FFFFFF;
}

.border-color-white {
  border-color: #FFFFFF;
}

.fill-white {
  fill: #FFFFFF;
}

.color-gray {
  color: #F2F2F2;
}

.background-color-gray {
  background-color: #F2F2F2;
}

.border-color-gray {
  border-color: #F2F2F2;
}

.fill-gray {
  fill: #F2F2F2;
}

.color-gray-2 {
  color: #CCCCCC;
}

.background-color-gray-2 {
  background-color: #CCCCCC;
}

.border-color-gray-2 {
  border-color: #CCCCCC;
}

.fill-gray-2 {
  fill: #CCCCCC;
}

.color-gray-3 {
  color: #999999;
}

.background-color-gray-3 {
  background-color: #999999;
}

.border-color-gray-3 {
  border-color: #999999;
}

.fill-gray-3 {
  fill: #999999;
}

.color-red {
  color: #D82E1F;
}

.background-color-red {
  background-color: #D82E1F;
}

.border-color-red {
  border-color: #D82E1F;
}

.fill-red {
  fill: #D82E1F;
}

.color-yellow {
  color: #FFE62E;
}

.background-color-yellow {
  background-color: #FFE62E;
}

.border-color-yellow {
  border-color: #FFE62E;
}

.fill-yellow {
  fill: #FFE62E;
}

body {
  font-size: 16px;
}
body.layout-pre {
  overflow-y: hidden;
}
body.layout-main {
  overflow-y: scroll;
}
body.layout-main.modal-open {
  overflow-y: scroll !important;
  padding-right: 0 !important;
}

.stop-scrolling {
  height: 100%;
  overflow: hidden !important;
}

.min-height-100-dvh {
  min-height: 100dvh;
}

button {
  color: #002A41;
}

.btn {
  text-transform: uppercase;
}
.btn.btn-sm {
  padding: 6px 7px;
  line-height: normal;
}
.btn.btn-rounded {
  border-radius: 100px;
}
.btn.text-normal {
  text-transform: none !important;
}
.btn.btn-primary {
  color: #fff;
  background-color: #35BDB6;
  border-color: #35BDB6;
}
.btn.btn-primary:hover {
  color: #fff;
  background-color: #2EA39D;
  border-color: #2EA39D;
}
.btn.btn-primary:focus, .btn.btn-primary.focus {
  color: #fff;
  background-color: #2EA39D;
  border-color: #2EA39D;
  box-shadow: 0 0 0 0.2rem rgba(83, 199, 193, 0.5);
}
.btn.btn-primary.disabled, .btn.btn-primary:disabled {
  color: #fff;
  background-color: #35BDB6;
  border-color: #35BDB6;
}
.btn.btn-primary:not(:disabled):not(.disabled):active, .btn.btn-primary:not(:disabled):not(.disabled).active, .show > .btn.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #2a9590;
  border-color: #278b86;
}
.btn.btn-primary:not(:disabled):not(.disabled):active:focus, .btn.btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(83, 199, 193, 0.5);
}
.btn.btn-custom-1 {
  color: #fff;
  background-color: #35BDB6;
  border-color: #35BDB6;
  text-transform: none;
  border-radius: 100px;
  padding: 8px 20px;
  color: #0D448C !important;
}
.btn.btn-custom-1:hover {
  color: #fff;
  background-color: #2EA39D;
  border-color: #2EA39D;
}
.btn.btn-custom-1:focus, .btn.btn-custom-1.focus {
  color: #fff;
  background-color: #2EA39D;
  border-color: #2EA39D;
  box-shadow: 0 0 0 0.2rem rgba(83, 199, 193, 0.5);
}
.btn.btn-custom-1.disabled, .btn.btn-custom-1:disabled {
  color: #fff;
  background-color: #35BDB6;
  border-color: #35BDB6;
}
.btn.btn-custom-1:not(:disabled):not(.disabled):active, .btn.btn-custom-1:not(:disabled):not(.disabled).active, .show > .btn.btn-custom-1.dropdown-toggle {
  color: #fff;
  background-color: #2a9590;
  border-color: #278b86;
}
.btn.btn-custom-1:not(:disabled):not(.disabled):active:focus, .btn.btn-custom-1:not(:disabled):not(.disabled).active:focus, .show > .btn.btn-custom-1.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(83, 199, 193, 0.5);
}
.btn.btn-secondary {
  color: #fff;
  background-color: #0D448C;
  border-color: #0D448C;
}
.btn.btn-secondary:hover {
  color: #fff;
  background-color: #083166;
  border-color: #083166;
}
.btn.btn-secondary:focus, .btn.btn-secondary.focus {
  color: #fff;
  background-color: #083166;
  border-color: #083166;
  box-shadow: 0 0 0 0.2rem rgba(49, 96, 157, 0.5);
}
.btn.btn-secondary.disabled, .btn.btn-secondary:disabled {
  color: #fff;
  background-color: #0D448C;
  border-color: #0D448C;
}
.btn.btn-secondary:not(:disabled):not(.disabled):active, .btn.btn-secondary:not(:disabled):not(.disabled).active, .show > .btn.btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #092d5d;
  border-color: #082852;
}
.btn.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn.btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn.btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(49, 96, 157, 0.5);
}
.btn.btn-danger {
  color: #fff;
  background-color: #D82E1F;
  border-color: #D82E1F;
}
.btn.btn-danger:hover {
  color: #fff;
  background-color: #A52317;
  border-color: #A52317;
}
.btn.btn-danger:focus, .btn.btn-danger.focus {
  color: #fff;
  background-color: #A52317;
  border-color: #A52317;
  box-shadow: 0 0 0 0.2rem rgba(222, 77, 65, 0.5);
}
.btn.btn-danger.disabled, .btn.btn-danger:disabled {
  color: #fff;
  background-color: #D82E1F;
  border-color: #D82E1F;
}
.btn.btn-danger:not(:disabled):not(.disabled):active, .btn.btn-danger:not(:disabled):not(.disabled).active, .show > .btn.btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #ab2519;
  border-color: #a02217;
}
.btn.btn-danger:not(:disabled):not(.disabled):active:focus, .btn.btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn.btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 77, 65, 0.5);
}
.btn.btn-outline-primary {
  color: #fff;
  background-color: transparent;
  border-color: #2EA39D;
  color: #2EA39D !important;
}
.btn.btn-outline-primary:hover {
  color: #212529;
  background-color: #F2F2F2;
  border-color: #2EA39D;
}
.btn.btn-outline-primary:focus, .btn.btn-outline-primary.focus {
  color: #212529;
  background-color: #F2F2F2;
  border-color: #2EA39D;
  box-shadow: 0 0 0 0.2rem rgba(77, 177, 172, 0.5);
}
.btn.btn-outline-primary.disabled, .btn.btn-outline-primary:disabled {
  color: #fff;
  background-color: transparent;
  border-color: #2EA39D;
}
.btn.btn-outline-primary:not(:disabled):not(.disabled):active, .btn.btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn.btn-outline-primary.dropdown-toggle {
  color: #212529;
  background-color: #F2F2F2;
  border-color: #2EA39D;
}
.btn.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn.btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn.btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(77, 177, 172, 0.5);
}
.btn.btn-outline-primary:hover, .btn.btn-outline-primary.hover {
  color: #2EA39D;
}
.btn.btn-outline-secondary {
  color: #fff;
  background-color: transparent;
  border-color: #0D448C;
  color: #0D448C !important;
}
.btn.btn-outline-secondary:hover {
  color: #212529;
  background-color: #F2F2F2;
  border-color: #0D448C;
}
.btn.btn-outline-secondary:focus, .btn.btn-outline-secondary.focus {
  color: #212529;
  background-color: #F2F2F2;
  border-color: #0D448C;
  box-shadow: 0 0 0 0.2rem rgba(49, 96, 157, 0.5);
}
.btn.btn-outline-secondary.disabled, .btn.btn-outline-secondary:disabled {
  color: #fff;
  background-color: transparent;
  border-color: #0D448C;
}
.btn.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn.btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn.btn-outline-secondary.dropdown-toggle {
  color: #212529;
  background-color: #F2F2F2;
  border-color: #0D448C;
}
.btn.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn.btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn.btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(49, 96, 157, 0.5);
}
.btn.btn-outline-secondary:hover, .btn.btn-outline-secondary.hover {
  color: #0D448C;
}
.btn:focus, .btn.focus {
  box-shadow: none !important;
}
.btn.disabled, .btn:disabled {
  border-color: #CCCCCC !important;
  background-color: #CCCCCC !important;
  color: #FFFFFF !important;
}
.btn i-tabler {
  display: inline-flex !important;
}
.btn.btn-link {
  padding: 0;
  border: none;
  text-decoration: underline;
}
.btn.btn-link.disabled, .btn.btn-link:disabled {
  background-color: transparent !important;
  color: #999999 !important;
  text-decoration: none;
}
.btn.btn-link--white {
  color: white;
}
.btn.btn-link--white:hover {
  color: #2EA39D;
}
.btn.btn-link--black {
  color: #002A41;
}
.btn.btn-link--black:hover {
  color: #2EA39D;
}

.custom-control.custom-checkbox .custom-control-label {
  font-weight: normal !important;
}
.custom-control.custom-checkbox .custom-control-label::before {
  top: 4px !important;
  width: 16px !important;
  height: 16px !important;
}
.custom-control.custom-checkbox .custom-control-label::after {
  top: 4px !important;
  width: 16px !important;
  height: 16px !important;
}
.custom-control.custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}
.custom-control.custom-checkbox .custom-control-input:checked:disabled ~ .custom-control-label::before {
  border-color: #999999;
}

.custom-control.custom-checkbox.primary .custom-control-input:checked:not(:disabled) ~ .custom-control-label::before {
  border-color: #35BDB6;
  background-color: #35BDB6;
}
.custom-control.custom-checkbox.primary .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #35BDB6;
}
.custom-control.custom-checkbox.primary .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  border-color: #35BDB6;
}

form.ng-submitted.on-submit-verification .custom-control-input.ng-invalid ~ .custom-control-label::before {
  border-color: #D82E1F;
}
form.ng-submitted.on-submit-verification .custom-control-input.ng-invalid ~ :is(label) {
  color: #D82E1F;
}
form.ng-submitted.on-submit-verification .custom-control-input.ng-invalid ~ :is(label) a {
  color: #D82E1F;
}

.dropdown-menu {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
  border: none;
}
.dropdown-menu li[role=menuitem].dropdown-item-no-actions {
  display: none;
}
.dropdown-menu li[role=menuitem].dropdown-item-no-actions:only-child {
  display: list-item;
}
.dropdown-menu a.dropdown-item {
  font-size: 0.875rem;
  font-weight: 700;
  padding-left: 24px;
  padding-right: 24px;
  cursor: pointer;
}
.dropdown-menu a.dropdown-item:active {
  color: inherit;
}
.dropdown-menu a.dropdown-item:hover, .dropdown-menu a.dropdown-item:focus-visible {
  background-color: rgba(242, 242, 242, 0.4);
  outline: 0;
}

.custom-file label.custom-file-label {
  font-weight: normal !important;
  margin-bottom: 0 !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  cursor: pointer;
}
.custom-file label.custom-file-label span {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.custom-file label.custom-file-label.with-drop {
  border: 1px dashed #ced4da;
}
.custom-file label.custom-file-label.with-drop.file-over {
  border: 1px dashed #2EA39D;
}
.custom-file label.custom-file-label.with-custom-file-button {
  padding-right: 157px !important;
}
.custom-file label.custom-file-label.with-custom-file-button.with-custom-file-clear-button {
  padding-right: 200px !important;
}
.custom-file label.custom-file-label.empty {
  color: #999999 !important;
}
.custom-file label.custom-file-label::after {
  display: none !important;
}
.custom-file.is-invalid label.custom-file-label {
  border-color: #D82E1F !important;
}
.custom-file input.custom-file-input[disabled] ~ button.custom-file-button {
  opacity: 0.5;
}
.custom-file button.custom-file-button {
  position: absolute;
  top: 6px;
  right: 7px;
  width: 140px;
  height: 28px;
  line-height: 16px;
  z-index: 10;
  pointer-events: none;
}
.custom-file button.custom-file-button svg-icon svg {
  width: 19px;
  height: 19px;
}
.custom-file button.custom-file-clear-button {
  position: absolute;
  top: 6px;
  right: 147px;
  height: 28px;
  line-height: 16px;
  z-index: 10;
  margin-right: 7px;
  font-size: 18px;
  padding-left: 8px;
  padding-right: 8px;
}
.custom-file button.custom-file-clear-button svg-icon svg {
  width: 19px;
  height: 19px;
}
.custom-file .custom-file-progress {
  position: absolute;
  height: 100%;
  width: 0;
  top: 0;
  left: 0;
  background-color: #2EA39D;
  z-index: 1;
  opacity: 0.1;
  border-radius: 0.25rem;
}

.form-group {
  width: 100%;
}
.form-group label {
  font-weight: 400;
}

.input-group .input-group-prepend .input-group-text {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.input-group .input-group-append .input-group-text {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

input.form-control.with-content {
  padding-right: 50px;
}

.form-control-plaintext {
  width: auto !important;
  height: auto !important;
}

.is-invalid ~ .invalid-feedback {
  display: block;
}

form.ng-submitted.on-submit-verification .ng-invalid ~ .invalid-feedback {
  display: block;
}

textarea.form-control {
  padding: 8px;
}

.custom-control.custom-radio .custom-control-input:checked:disabled ~ .custom-control-label::before {
  border-color: transparent;
}
.custom-control.custom-radio .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}

.custom-control.custom-radio.primary .custom-control-input:checked:not(:disabled) ~ .custom-control-label::before {
  border-color: #35BDB6;
  background-color: #35BDB6;
}
.custom-control.custom-radio.primary .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #35BDB6;
}
.custom-control.custom-radio.primary .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  border-color: #35BDB6;
}

input.form-control:disabled {
  color: #999999;
  background-color: #E9ECEF;
  border: 1px solid #CED4DA;
}
input.form-control:disabled ~ .invalid-feedback {
  display: none;
}

form.on-submit-verification.ng-submitted textarea.form-control.ng-invalid, form.on-submit-verification.ng-submitted input.textarea.ng-invalid {
  border-color: #D82E1F;
}

.custom-control.custom-switch .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}

.custom-control.custom-switch.primary .custom-control-input:checked:not(:disabled) ~ .custom-control-label::before {
  border-color: #35BDB6;
  background-color: #35BDB6;
}
.custom-control.custom-switch.primary .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #35BDB6;
}
.custom-control.custom-switch.primary .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  border-color: #35BDB6;
}

table.table:not(.table-borderless) {
  border: 1px solid #dee2e6 !important;
}
table.table.table-ellipsis-wrap td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 0;
}
table.table td.control {
  padding: 0.5rem !important;
}

tabset.tab-container .nav-tabs {
  border-bottom-color: #DEE2E6;
}
tabset.tab-container .nav-tabs a {
  color: #002A41;
}
tabset.tab-container .nav-tabs li:focus-visible, tabset.tab-container .nav-tabs a:focus-visible {
  outline: 0;
}
tabset.tab-container .nav-tabs .nav-link.disabled {
  color: #999999;
}
tabset.tab-container .nav-tabs .nav-link.active, tabset.tab-container .nav-tabs .nav-item.show .nav-link {
  color: #2EA39D;
  background-color: transparent;
  border-color: #DEE2E6 #DEE2E6 white;
}
tabset.tab-container .nav-pills a {
  color: #2EA39D;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
}
tabset.tab-container .nav-pills li:focus-visible, tabset.tab-container .nav-pills a:focus-visible {
  color: #35BDB6;
  outline: 0;
}
tabset.tab-container .nav-pills .nav-link.active, tabset.tab-container .nav-pills .nav-item.show .nav-link {
  color: #FFFFFF;
  background-color: #35BDB6;
}

.password-toggle {
  position: relative;
}
.password-toggle > input {
  padding-right: 40px;
}
.password-toggle > input ~ .password-toggle-icon {
  position: absolute;
  top: 50%;
  right: 0.75rem;
  display: block;
  width: 18px;
  height: 18px;
  mask-image: url("/asset/image/icon/eye.svg");
  mask-size: contain;
  mask-position: center;
  mask-repeat: no-repeat;
  background-color: #002A41;
  transform: translateY(-50%);
  cursor: pointer;
}
.password-toggle > input[type=text] ~ .password-toggle-icon {
  mask-image: url("/asset/image/icon/eye-off.svg");
}
.password-toggle > input.is-valid {
  background-image: none;
}
.password-toggle > input.is-valid ~ .password-toggle-icon {
  background-color: #2EA39D;
}
.password-toggle > input.is-invalid {
  background-image: none;
}
.password-toggle > input.is-invalid ~ .password-toggle-icon {
  background-color: #D82E1F;
}
.password-toggle > label + input ~ .password-toggle-icon {
  top: 40px;
  transform: none;
}

.ng-select {
  font-size: 1rem;
}
.ng-select.ng-select-single .ng-select-container {
  height: 40px;
}
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 50%;
  left: 0;
  padding-left: 0.5rem;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  border-radius: 0.25rem;
}
.ng-select .ng-select-container {
  min-height: 40px;
  border-radius: 0.25rem;
}
.ng-select .ng-select-container .ng-value-container {
  padding: 0.5rem 0.5rem;
}
.ng-select.sm {
  font-size: 0.8rem;
}
.ng-select.sm.ng-select-single .ng-select-container {
  height: 36px;
}
.ng-select.sm.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 50%;
  left: 0;
  padding-left: 0.5rem;
}
.ng-select.sm.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  border-radius: 0.2rem;
}
.ng-select.sm .ng-select-container {
  min-height: 36px;
  border-radius: 0.2rem;
}
.ng-select.sm .ng-select-container .ng-value-container {
  padding: 0.25rem 0.5rem;
}
.ng-select .ng-select-container {
  align-items: center;
  border: 1px solid #ced4da;
  color: #495057;
  background-color: #fff;
}
.ng-select .ng-select-container .ng-value-container {
  align-items: center;
}
.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: #999999;
}
.ng-select .ng-clear-wrapper {
  display: flex;
  align-items: center;
  width: 8px !important;
}
.ng-select .ng-clear-wrapper .ng-clear {
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  transition: 0.15s color;
}
.ng-select .ng-clear-wrapper:hover .ng-clear {
  color: #2EA39D;
}
.ng-select .ng-spinner-zone {
  padding: 5px 5px 0 0;
}
.ng-select .ng-arrow-wrapper {
  width: 24px;
  margin-right: 5px;
  margin-left: 5px;
}
.ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: #002A41 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 2.5px;
}
.ng-select.ng-select-opened .ng-select-container, .ng-select.ng-select-focused .ng-select-container {
  border-color: #78d9d4;
  box-shadow: 0px 0px 0px 3.2px rgba(0, 123, 255, 0.25);
}
.ng-select.ng-select-opened .ng-arrow-wrapper .ng-arrow {
  top: -2px;
  border-color: transparent transparent #002A41;
  border-width: 0 5px 5px;
}
.ng-select.ng-select-disabled, .ng-select[disabled] {
  pointer-events: none;
}
.ng-select.ng-select-disabled .ng-select-container, .ng-select[disabled] .ng-select-container {
  color: #999999;
  background-color: #E9ECEF;
  border: 1px solid #CED4DA !important;
}
.ng-select.ng-select-disabled ~ .invalid-feedback, .ng-select[disabled] ~ .invalid-feedback {
  display: none;
}
.ng-select .ng-has-value .ng-placeholder {
  display: none;
}
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 50%;
  left: 0;
  padding-right: 60px;
  transform: translateY(-50%);
}
.ng-select.ng-select-single .ng-select-container .ng-value-container input {
  color: #495057;
}
.ng-select.ng-select-multiple.ng-select-disabled .ng-select-container .ng-value-container .ng-value {
  background-color: #E9ECEF;
  border: 1px solid #ced4da;
}
.ng-select.ng-select-multiple.ng-select-disabled .ng-select-container .ng-value-container .ng-value .ng-value-label {
  padding: 0 5px;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  border: 1px solid #ced4da;
  background-color: #fff;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled {
  background-color: #E9ECEF;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label {
  padding-left: 5px;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
  padding: 5px;
  font-size: 80%;
  line-height: 1;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
  position: relative;
  width: 21px;
  height: 100%;
  border-left: 1px solid #ced4da;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:after {
  position: absolute;
  left: 50%;
  top: 0;
  width: 18px;
  height: 100%;
  content: "";
  mask-image: url("/asset/image/icon/point.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: 50% 50%;
  background-color: #adb5bd;
  transform: translateX(-50%);
  transition: background-color 0.15s;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover:after {
  background-color: #6c757d;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input input {
  color: #495057;
}
.ng-select.is-invalid .ng-select-container, .ng-select.ng-invalid .ng-select-container {
  border-color: #D82E1F;
}
.ng-select.is-invalid.ng-select-opened .ng-select-container, .ng-select.ng-invalid.ng-select-opened .ng-select-container {
  border-color: #D82E1F;
  box-shadow: 0 0 0 0.2rem rgba(216, 46, 31, 0.25);
}
.ng-select.is-invalid.ng-select-focused:not(.ng-select-opened) .ng-select-container, .ng-select.ng-invalid.ng-select-focused:not(.ng-select-opened) .ng-select-container {
  border-color: #D82E1F;
  box-shadow: 0 0 0 0.2rem rgba(216, 46, 31, 0.25);
}
.ng-select .ng-dropdown-panel {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  left: 0;
}
.ng-select .ng-dropdown-panel.ng-select-bottom {
  top: 100%;
  margin-top: 5px;
}
.ng-select .ng-dropdown-panel.ng-select-top {
  bottom: 100%;
  margin-bottom: 5px;
}
.ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option:first-child {
  border-top-right-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
}
.ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.ng-select .ng-dropdown-panel .ng-dropdown-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding: 5px 7px;
}
.ng-select .ng-dropdown-panel .ng-dropdown-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  padding: 5px 7px;
}
.ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
  user-select: none;
  padding: 5px 10px;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
}
.ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-disabled {
  cursor: default;
}
.ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked {
  background-color: #F2F2F2;
  color: #16181b;
}
.ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected {
  background-color: #2EA39D;
  color: #fff;
}
.ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background-color: #fff;
  color: #002A41;
  padding: 5px 10px;
  font-size: 1rem;
}
.ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  color: #fff;
  background-color: #2EA39D;
}
.ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked:not(.ng-option-selected) {
  background-color: rgba(242, 242, 242, 0.4);
  color: #16181b;
}
.ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
  color: #adb5bd;
}
.ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
  padding-left: 22px;
}
.ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
  font-size: 80%;
  padding-right: 5px;
}

input[bsdatepicker] {
  width: 200px !important;
}
input[bsdatepicker].full-width {
  width: 100% !important;
}
input[bsdatepicker].with-datepicker-clear-button {
  padding-right: 40px !important;
}
input[bsdatepicker].with-placeholder-as-value::placeholder {
  color: #F2F2F2 !important;
}
input[bsdatepicker] + div.datepicker-clear-button-wrapper {
  position: relative;
  align-self: flex-start;
  line-height: 21.6px;
}
input[bsdatepicker] + div.datepicker-clear-button-wrapper button.datepicker-clear-button {
  position: absolute;
  right: 6px;
  top: 4px !important;
  font-size: 18px !important;
  line-height: 18px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
  padding-top: 4px !important;
  padding-bottom: 3px !important;
}
input[bsdatepicker] ~ div.datepicker-clear-button-wrapper.standalone {
  margin-left: 10px;
}
input[bsdatepicker] ~ div.datepicker-clear-button-wrapper.standalone button.datepicker-clear-button {
  font-size: 20px !important;
  line-height: 20px !important;
  height: calc(100% - 1px);
}

.bs-datepicker.theme-custom .bs-datepicker-head {
  background-color: #2EA39D;
}
.bs-datepicker.theme-custom .bs-datepicker-body table td span.selected,
.bs-datepicker.theme-custom .bs-datepicker-body table td.selected span,
.bs-datepicker.theme-custom .bs-datepicker-body table td span[class*=select-]:after,
.bs-datepicker.theme-custom .bs-datepicker-body table td[class*=select-] span:after {
  background-color: #2EA39D;
}
.bs-datepicker.theme-custom .bs-datepicker-body table td.week span {
  color: #2EA39D;
}

bs-dropdown-container {
  z-index: 4000;
}

.modal-dialog.full-screen {
  max-width: 100%;
  height: auto;
  margin: 0;
}
.modal-dialog.full-screen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

modal-container.modal {
  z-index: 3000 !important;
}
modal-container.modal .modal-header {
  align-items: center;
  padding: 20px;
  border-bottom: none;
}
modal-container.modal .modal-header .modal-title {
  padding-right: 40px;
  font-size: 20px;
}
modal-container.modal .modal-header .modal-control {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 36px;
}
modal-container.modal .modal-body {
  font-size: 16px;
}
modal-container.modal .modal-footer {
  border: none;
}
modal-container button.close-button {
  background: none;
  border: none;
}

bs-modal-backdrop {
  z-index: 2000 !important;
}

timepicker {
  margin-top: -1px !important;
}
timepicker input::placeholder {
  opacity: 0 !important;
}
timepicker.is-invalid input.form-control {
  border-color: #D82E1F !important;
}
timepicker.is-invalid input.form-control:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 46, 31, 0.25) !important;
}
timepicker.is-valid input.form-control {
  border-color: #2EA39D !important;
}
timepicker.is-valid input.form-control:focus {
  box-shadow: 0 0 0 0.2rem rgba(46, 163, 157, 0.25) !important;
}

ngx-slider.ngx-slider:not(.ngx-slider-vertical) {
  margin: 0 !important;
  margin-top: 15px !important;
  height: 45px !important;
}
ngx-slider.ngx-slider:not(.ngx-slider-vertical) .ngx-slider-tick-value {
  top: 25px !important;
  color: #2EA39D !important;
  font-weight: bold;
}
ngx-slider.ngx-slider:not(.ngx-slider-vertical).custom .ngx-slider-tick {
  background: none !important;
}
ngx-slider.ngx-slider:not(.ngx-slider-vertical).custom .ngx-slider-bar {
  height: 22px !important;
  background: none !important;
  border: 5px solid #F2F2F2;
  border-radius: 10px !important;
  top: 8px !important;
}
ngx-slider.ngx-slider .ngx-slider-pointer {
  background-color: #2EA39D !important;
}
ngx-slider.ngx-slider .ngx-slider-pointer:hover {
  background-color: #2EA39D !important;
}
ngx-slider.ngx-slider .ngx-slider-pointer::after {
  background-color: #2EA39D !important;
}
ngx-slider[disabled]:not(.ngx-slider-vertical).custom .ngx-slider-bar {
  background: #E9ECEF !important;
}

#toast-container.toast-bottom-center .ngx-toastr {
  width: auto !important;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}
#toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
  pointer-events: auto;
}

.ngx-toast:not(.toast-success):not(.toast-error):not(.toast-info):not(.toast-warning) {
  background-color: #030303;
}

.toast-success {
  /* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/solid/check.svg */
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgba(255, 255, 255, 0.999999)' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E") !important;
  color: #FFFFFF !important;
  background-color: #60b9b5 !important;
  border: 1px solid #4fb2ad !important;
}

.toast-error {
  /* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/solid/times-circle.svg */
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgba(255, 255, 255, 0.999999)' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/%3E%3C/svg%3E") !important;
  color: #FFFFFF;
  background-color: #e16055 !important;
  border: 1px solid #de4f43 !important;
}

.toast-info {
  /* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/solid/info-circle.svg */
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgba(255, 255, 255, 0.999999)' d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z'/%3E%3C/svg%3E") !important;
  color: #FFFFFF;
  background-color: #74c7d4 !important;
  border: 1px solid #61c0cf !important;
}

.toast-warning {
  /* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/solid/exclamation-triangle.svg */
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' width='576' height='512'%3E%3Cpath fill='rgba(255, 255, 255, 0.999999)' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E") !important;
  color: #FFFFFF;
  background-color: #ffc61b !important;
  border: 1px solid #ffc107 !important;
}

@media (min-width: 1900px) {
  #toast-container.toast-bottom-center .ngx-toastr {
    max-width: 50%;
  }
}
svg-icon svg {
  vertical-align: top !important;
}
svg-icon.icon-center svg {
  vertical-align: baseline !important;
}

@font-face {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 100;
  src: url("./../asset/font/proximaNova/ProximaNovaThin.otf") format("opentype");
}
@font-face {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 300;
  src: url("./../asset/font/proximaNova/ProximaNovaLight.otf") format("opentype");
}
@font-face {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  src: url("./../asset/font/proximaNova/ProximaNovaRegular.otf") format("opentype");
}
@font-face {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 500;
  src: url("./../asset/font/proximaNova/ProximaNovaSemibold.otf") format("opentype");
}
@font-face {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 700;
  src: url("./../asset/font/proximaNova/ProximaNovaBold.otf") format("opentype");
}
@font-face {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 800;
  src: url("./../asset/font/proximaNova/ProximaNovaExtrabold.otf") format("opentype");
}
i-tabler.i-sm {
  width: 16px;
  height: 16px;
}
i-tabler.i-md {
  width: 20px;
  height: 20px;
}
i-tabler.i-lg {
  width: 28px;
  height: 28px;
}
i-tabler.i-xl {
  width: 32px;
  height: 32px;
}

.font-weight-100 {
  font-weight: 100 !important;
}

.font-weight-300 {
  font-weight: 300 !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.font-weight-800 {
  font-weight: 800 !important;
}

.rotate-45 {
  transform: rotate(45deg);
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-135 {
  transform: rotate(135deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

.rotate-225 {
  transform: rotate(225deg);
}

.rotate-270 {
  transform: rotate(270deg);
}

.rotate-315 {
  transform: rotate(315deg);
}

.font-size-sm {
  font-size: 0.8rem !important;
}

.font-size-base {
  font-size: 1rem !important;
}

.font-size-lg {
  font-size: 1.25rem !important;
}

.space-x-0 > * + * {
  margin-left: 0 !important;
}

.space-y-0 > * + * {
  margin-top: 0 !important;
}

.space-x-1 > * + * {
  margin-left: 0.5rem !important;
}

.space-y-1 > * + * {
  margin-top: 0.5rem !important;
}

.space-x-2 > * + * {
  margin-left: 1rem !important;
}

.space-y-2 > * + * {
  margin-top: 1rem !important;
}

.space-x-3 > * + * {
  margin-left: 1.5rem !important;
}

.space-y-3 > * + * {
  margin-top: 1.5rem !important;
}

.space-x-4 > * + * {
  margin-left: 2rem !important;
}

.space-y-4 > * + * {
  margin-top: 2rem !important;
}

.space-x-5 > * + * {
  margin-left: 2.5rem !important;
}

.space-y-5 > * + * {
  margin-top: 2.5rem !important;
}

.space-x-6 > * + * {
  margin-left: 3rem !important;
}

.space-y-6 > * + * {
  margin-top: 3rem !important;
}

.space-x-7 > * + * {
  margin-left: 3.5rem !important;
}

.space-y-7 > * + * {
  margin-top: 3.5rem !important;
}

.space-x-8 > * + * {
  margin-left: 4rem !important;
}

.space-y-8 > * + * {
  margin-top: 4rem !important;
}

@media (min-width: 768px) {
  .space-x-sm-0 > * + * {
    margin-left: 0 !important;
  }

  .space-y-sm-0 > * + * {
    margin-top: 0 !important;
  }

  .space-x-sm-1 > * + * {
    margin-left: 0.5rem !important;
  }

  .space-y-sm-1 > * + * {
    margin-top: 0.5rem !important;
  }

  .space-x-sm-2 > * + * {
    margin-left: 1rem !important;
  }

  .space-y-sm-2 > * + * {
    margin-top: 1rem !important;
  }

  .space-x-sm-3 > * + * {
    margin-left: 1.5rem !important;
  }

  .space-y-sm-3 > * + * {
    margin-top: 1.5rem !important;
  }

  .space-x-sm-4 > * + * {
    margin-left: 2rem !important;
  }

  .space-y-sm-4 > * + * {
    margin-top: 2rem !important;
  }

  .space-x-sm-5 > * + * {
    margin-left: 2.5rem !important;
  }

  .space-y-sm-5 > * + * {
    margin-top: 2.5rem !important;
  }

  .space-x-sm-6 > * + * {
    margin-left: 3rem !important;
  }

  .space-y-sm-6 > * + * {
    margin-top: 3rem !important;
  }

  .space-x-sm-7 > * + * {
    margin-left: 3.5rem !important;
  }

  .space-y-sm-7 > * + * {
    margin-top: 3.5rem !important;
  }

  .space-x-sm-8 > * + * {
    margin-left: 4rem !important;
  }

  .space-y-sm-8 > * + * {
    margin-top: 4rem !important;
  }
}
@media (min-width: 1200px) {
  .space-x-md-0 > * + * {
    margin-left: 0 !important;
  }

  .space-y-md-0 > * + * {
    margin-top: 0 !important;
  }

  .space-x-md-1 > * + * {
    margin-left: 0.5rem !important;
  }

  .space-y-md-1 > * + * {
    margin-top: 0.5rem !important;
  }

  .space-x-md-2 > * + * {
    margin-left: 1rem !important;
  }

  .space-y-md-2 > * + * {
    margin-top: 1rem !important;
  }

  .space-x-md-3 > * + * {
    margin-left: 1.5rem !important;
  }

  .space-y-md-3 > * + * {
    margin-top: 1.5rem !important;
  }

  .space-x-md-4 > * + * {
    margin-left: 2rem !important;
  }

  .space-y-md-4 > * + * {
    margin-top: 2rem !important;
  }

  .space-x-md-5 > * + * {
    margin-left: 2.5rem !important;
  }

  .space-y-md-5 > * + * {
    margin-top: 2.5rem !important;
  }

  .space-x-md-6 > * + * {
    margin-left: 3rem !important;
  }

  .space-y-md-6 > * + * {
    margin-top: 3rem !important;
  }

  .space-x-md-7 > * + * {
    margin-left: 3.5rem !important;
  }

  .space-y-md-7 > * + * {
    margin-top: 3.5rem !important;
  }

  .space-x-md-8 > * + * {
    margin-left: 4rem !important;
  }

  .space-y-md-8 > * + * {
    margin-top: 4rem !important;
  }
}
@media (min-width: 1400px) {
  .space-x-lg-0 > * + * {
    margin-left: 0 !important;
  }

  .space-y-lg-0 > * + * {
    margin-top: 0 !important;
  }

  .space-x-lg-1 > * + * {
    margin-left: 0.5rem !important;
  }

  .space-y-lg-1 > * + * {
    margin-top: 0.5rem !important;
  }

  .space-x-lg-2 > * + * {
    margin-left: 1rem !important;
  }

  .space-y-lg-2 > * + * {
    margin-top: 1rem !important;
  }

  .space-x-lg-3 > * + * {
    margin-left: 1.5rem !important;
  }

  .space-y-lg-3 > * + * {
    margin-top: 1.5rem !important;
  }

  .space-x-lg-4 > * + * {
    margin-left: 2rem !important;
  }

  .space-y-lg-4 > * + * {
    margin-top: 2rem !important;
  }

  .space-x-lg-5 > * + * {
    margin-left: 2.5rem !important;
  }

  .space-y-lg-5 > * + * {
    margin-top: 2.5rem !important;
  }

  .space-x-lg-6 > * + * {
    margin-left: 3rem !important;
  }

  .space-y-lg-6 > * + * {
    margin-top: 3rem !important;
  }

  .space-x-lg-7 > * + * {
    margin-left: 3.5rem !important;
  }

  .space-y-lg-7 > * + * {
    margin-top: 3.5rem !important;
  }

  .space-x-lg-8 > * + * {
    margin-left: 4rem !important;
  }

  .space-y-lg-8 > * + * {
    margin-top: 4rem !important;
  }
}
@media (min-width: 1900px) {
  .space-x-xl-0 > * + * {
    margin-left: 0 !important;
  }

  .space-y-xl-0 > * + * {
    margin-top: 0 !important;
  }

  .space-x-xl-1 > * + * {
    margin-left: 0.5rem !important;
  }

  .space-y-xl-1 > * + * {
    margin-top: 0.5rem !important;
  }

  .space-x-xl-2 > * + * {
    margin-left: 1rem !important;
  }

  .space-y-xl-2 > * + * {
    margin-top: 1rem !important;
  }

  .space-x-xl-3 > * + * {
    margin-left: 1.5rem !important;
  }

  .space-y-xl-3 > * + * {
    margin-top: 1.5rem !important;
  }

  .space-x-xl-4 > * + * {
    margin-left: 2rem !important;
  }

  .space-y-xl-4 > * + * {
    margin-top: 2rem !important;
  }

  .space-x-xl-5 > * + * {
    margin-left: 2.5rem !important;
  }

  .space-y-xl-5 > * + * {
    margin-top: 2.5rem !important;
  }

  .space-x-xl-6 > * + * {
    margin-left: 3rem !important;
  }

  .space-y-xl-6 > * + * {
    margin-top: 3rem !important;
  }

  .space-x-xl-7 > * + * {
    margin-left: 3.5rem !important;
  }

  .space-y-xl-7 > * + * {
    margin-top: 3.5rem !important;
  }

  .space-x-xl-8 > * + * {
    margin-left: 4rem !important;
  }

  .space-y-xl-8 > * + * {
    margin-top: 4rem !important;
  }
}
.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.5rem !important;
}

.gap-2 {
  gap: 1rem !important;
}

.gap-3 {
  gap: 1.5rem !important;
}

.gap-4 {
  gap: 2rem !important;
}

.gap-5 {
  gap: 2.5rem !important;
}

.gap-6 {
  gap: 3rem !important;
}

.gap-7 {
  gap: 3.5rem !important;
}

.gap-8 {
  gap: 4rem !important;
}

@media (min-width: 768px) {
  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.5rem !important;
  }

  .gap-sm-2 {
    gap: 1rem !important;
  }

  .gap-sm-3 {
    gap: 1.5rem !important;
  }

  .gap-sm-4 {
    gap: 2rem !important;
  }

  .gap-sm-5 {
    gap: 2.5rem !important;
  }

  .gap-sm-6 {
    gap: 3rem !important;
  }

  .gap-sm-7 {
    gap: 3.5rem !important;
  }

  .gap-sm-8 {
    gap: 4rem !important;
  }
}
@media (min-width: 1200px) {
  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.5rem !important;
  }

  .gap-md-2 {
    gap: 1rem !important;
  }

  .gap-md-3 {
    gap: 1.5rem !important;
  }

  .gap-md-4 {
    gap: 2rem !important;
  }

  .gap-md-5 {
    gap: 2.5rem !important;
  }

  .gap-md-6 {
    gap: 3rem !important;
  }

  .gap-md-7 {
    gap: 3.5rem !important;
  }

  .gap-md-8 {
    gap: 4rem !important;
  }
}
@media (min-width: 1400px) {
  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.5rem !important;
  }

  .gap-lg-2 {
    gap: 1rem !important;
  }

  .gap-lg-3 {
    gap: 1.5rem !important;
  }

  .gap-lg-4 {
    gap: 2rem !important;
  }

  .gap-lg-5 {
    gap: 2.5rem !important;
  }

  .gap-lg-6 {
    gap: 3rem !important;
  }

  .gap-lg-7 {
    gap: 3.5rem !important;
  }

  .gap-lg-8 {
    gap: 4rem !important;
  }
}
@media (min-width: 1900px) {
  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.5rem !important;
  }

  .gap-xl-2 {
    gap: 1rem !important;
  }

  .gap-xl-3 {
    gap: 1.5rem !important;
  }

  .gap-xl-4 {
    gap: 2rem !important;
  }

  .gap-xl-5 {
    gap: 2.5rem !important;
  }

  .gap-xl-6 {
    gap: 3rem !important;
  }

  .gap-xl-7 {
    gap: 3.5rem !important;
  }

  .gap-xl-8 {
    gap: 4rem !important;
  }
}
.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

@media (min-width: 768px) {
  .w-sm-25 {
    width: 25% !important;
  }

  .w-sm-50 {
    width: 50% !important;
  }

  .w-sm-75 {
    width: 75% !important;
  }

  .w-sm-100 {
    width: 100% !important;
  }

  .w-sm-auto {
    width: auto !important;
  }

  .h-sm-25 {
    height: 25% !important;
  }

  .h-sm-50 {
    height: 50% !important;
  }

  .h-sm-75 {
    height: 75% !important;
  }

  .h-sm-100 {
    height: 100% !important;
  }

  .h-sm-auto {
    height: auto !important;
  }
}
@media (min-width: 1200px) {
  .w-md-25 {
    width: 25% !important;
  }

  .w-md-50 {
    width: 50% !important;
  }

  .w-md-75 {
    width: 75% !important;
  }

  .w-md-100 {
    width: 100% !important;
  }

  .w-md-auto {
    width: auto !important;
  }

  .h-md-25 {
    height: 25% !important;
  }

  .h-md-50 {
    height: 50% !important;
  }

  .h-md-75 {
    height: 75% !important;
  }

  .h-md-100 {
    height: 100% !important;
  }

  .h-md-auto {
    height: auto !important;
  }
}
@media (min-width: 1400px) {
  .w-lg-25 {
    width: 25% !important;
  }

  .w-lg-50 {
    width: 50% !important;
  }

  .w-lg-75 {
    width: 75% !important;
  }

  .w-lg-100 {
    width: 100% !important;
  }

  .w-lg-auto {
    width: auto !important;
  }

  .h-lg-25 {
    height: 25% !important;
  }

  .h-lg-50 {
    height: 50% !important;
  }

  .h-lg-75 {
    height: 75% !important;
  }

  .h-lg-100 {
    height: 100% !important;
  }

  .h-lg-auto {
    height: auto !important;
  }
}
@media (min-width: 1900px) {
  .w-xl-25 {
    width: 25% !important;
  }

  .w-xl-50 {
    width: 50% !important;
  }

  .w-xl-75 {
    width: 75% !important;
  }

  .w-xl-100 {
    width: 100% !important;
  }

  .w-xl-auto {
    width: auto !important;
  }

  .h-xl-25 {
    height: 25% !important;
  }

  .h-xl-50 {
    height: 50% !important;
  }

  .h-xl-75 {
    height: 75% !important;
  }

  .h-xl-100 {
    height: 100% !important;
  }

  .h-xl-auto {
    height: auto !important;
  }
}
.colon-after:after {
  content: ":";
}

.asterisk-after:after {
  content: " *";
}

.pointer {
  cursor: pointer !important;
}

.whitespace-pre-wrap {
  white-space: pre-wrap !important;
}

.whitespace-normal {
  white-space: normal !important;
}

.selectable {
  -webkit-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  user-select: text !important;
}

.page .header-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 2rem;
  min-height: 70px;
}
.page .header-container div.header {
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 28px;
  padding-right: 0.5rem;
}
.page .header-container button.back {
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 0;
  border: 0;
  padding: 0;
  background-color: transparent;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 28px;
}
.page .header-container button.back i-tabler {
  display: flex;
  width: 30px;
  height: 30px;
}
.page .header-container button.back:hover, .page .header-container button.back:focus-visible {
  color: #2EA39D;
}
.page .header-container .actions {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  flex-wrap: wrap;
  justify-content: end;
}
.page .header-container app-page-action-container:has(> div:empty) {
  display: none;
}
.page .header-container.separator {
  border-bottom: 1px solid #F2F2F2;
}
.page .header-container.separator ~ .content {
  padding-top: 2rem;
}

@media (min-width: 768px) {
  .page .header-container {
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }
  .page .header-container .actions, .page .header-container app-page-action-container > #page-action-container {
    margin-top: 0;
  }
}
.th-badge {
  padding: 3px 4px;
  font-size: 10px;
  font-weight: 500;
  border-radius: 4px;
}
.th-badge--yellow {
  background-color: #FFE62E;
}
.th-badge--secondary {
  background-color: #0D448C;
  color: #FFFFFF;
}
.th-badge--black {
  background-color: #002A41;
  color: #FFFFFF;
}
.th-badge--danger {
  background-color: #D82E1F;
  color: #FFFFFF;
}
.th-badge--gray {
  background-color: #999999;
  color: #FFFFFF;
}

#layout-migrating {
  min-height: 100dvh;
  background-color: #FFFFFF;
}
#layout-migrating #logo {
  width: 250px;
}
#layout-migrating .container {
  max-width: 1100px;
  padding-top: 60px;
}
#layout-migrating a.btn-link {
  text-transform: lowercase !important;
  vertical-align: baseline !important;
}
#layout-migrating .validation-error {
  display: none;
  color: #D82E1F;
}
#layout-migrating form.ng-submitted.ng-invalid.on-submit-verification .validation-error {
  display: block;
}