@use 'variables/_color' as color;
@use 'bootstrap';

button {
  color: color.$black;
}

.btn {
  text-transform: uppercase;

  &.btn-sm {
    padding: 6px 7px;
    line-height: normal;
  }

  &.btn-rounded {
    border-radius: 100px;
  }

  &.text-normal {
    text-transform: none !important;
  }

  &.btn-primary {
    @include bootstrap.button-variant(color.$green-2, color.$green-2, color.$green, color.$green);
  }

  &.btn-custom-1 {
    @include bootstrap.button-variant(color.$green-2, color.$green-2, color.$green, color.$green);

    text-transform: none;
    border-radius: 100px;
    padding: 8px 20px;
    color: color.$blue !important;
  }

  &.btn-secondary {
    @include bootstrap.button-variant(color.$blue, color.$blue, color.$blue-2, color.$blue-2);
  }

  &.btn-danger {
    @include bootstrap.button-variant(color.$red, color.$red, color.$red-2, color.$red-2);
  }

  &.btn-outline-primary {
    @include bootstrap.button-variant(transparent, color.$green, color.$gray, color.$green, color.$gray, color.$green);
    color: color.$green !important;

    &:hover,
    &.hover {
      color: color.$green;
    }
  }

  &.btn-outline-secondary {
    @include bootstrap.button-variant(transparent, color.$blue, color.$gray, color.$blue, color.$gray, color.$blue);
    color: color.$blue !important;

    &:hover,
    &.hover {
      color: color.$blue;
    }
  }

  &:focus,
  &.focus {
    box-shadow: none !important;
  }

  &.disabled,
  &:disabled {
    border-color: color.$gray-2 !important;
    background-color: color.$gray-2 !important;
    color: color.$white !important;
  }

  i-tabler {
    display: inline-flex !important;
  }

  &.btn-link {
    padding: 0;
    border: none;
    text-decoration: underline;

    &.disabled,
    &:disabled {
      background-color: transparent !important;
      color: color.$gray-3 !important;
      text-decoration: none;
    }
  }


  &.btn-link {
    &--white {
      color: white;

      @include bootstrap.hover() {
        color: color.$primary;
      }
    }

    &--black {
      color: color.$black;

      @include bootstrap.hover() {
        color: color.$primary;
      }
    }
  }
}