@use 'variables/_color' as color;

@each $color-name, $color-value in color.$colors {
  .color-#{$color-name} {
    color: $color-value;
  }

  .background-color-#{$color-name} {
    background-color: $color-value;
  }

  .border-color-#{$color-name} {
    border-color: $color-value;
  }

  .fill-#{$color-name} {
    fill: $color-value;
  }
}

