@use 'variables/_color' as color;
@use 'bootstrap' as bootstrap;

.custom-file {
  label.custom-file-label {
    font-weight: normal !important;
    margin-bottom: 0 !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
    cursor: pointer;

    span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &.with-drop {
      border: 1px dashed bootstrap.$input-border-color;

      &.file-over {
        border: 1px dashed color.$primary;
      }
    }

    &.with-custom-file-button {
      padding-right: 157px !important;

      &.with-custom-file-clear-button {
        padding-right: 200px !important;
      }
    }

    &.empty {
      color: bootstrap.$input-placeholder-color !important;
    }

    &::after {
      display: none !important;
    }
  }

  &.is-invalid {
    label.custom-file-label {
      border-color: color.$danger !important;
    }
  }

  input.custom-file-input[disabled] ~  button.custom-file-button {
    opacity: 0.5;
  }

  button.custom-file-button {
    position: absolute;
    top: 6px;
    right: 7px;
    width: 140px;
    height: 28px;
    line-height: 16px;
    z-index: 10;
    pointer-events: none;

    svg-icon {
      svg {
        width: 19px;
        height: 19px;
      }
    }
  }

  button.custom-file-clear-button {
    position: absolute;
    top: 6px;
    right: 147px;
    height: 28px;
    line-height: 16px;
    z-index: 10;
    margin-right: 7px;
    font-size: 18px;
    padding-left: 8px;
    padding-right: 8px;

    svg-icon {
      svg {
        width: 19px;
        height: 19px;
      }
    }
  }

  .custom-file-progress {
    position: absolute;
    height: 100%;
    width: 0;
    top: 0;
    left: 0;
    background-color: color.$primary;
    z-index: 1;
    opacity: 0.1;
    border-radius: bootstrap.$input-border-radius;
  }
}
