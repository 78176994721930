@use 'variables/color';

.custom-control.custom-checkbox {
  .custom-control-label {
    font-weight: normal !important;

    &::before {
      top: 4px !important;
      width: 16px !important;
      height: 16px !important;
    }

    &::after {
      top: 4px !important;
      width: 16px !important;
      height: 16px !important;
    }
  }

  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none;
  }

  .custom-control-input:checked:disabled ~ .custom-control-label::before {
    border-color: color.$gray-3;
  }
}

.custom-control.custom-checkbox.primary {
  .custom-control-input:checked:not(:disabled) ~ .custom-control-label::before {
    border-color: color.$green-2;
    background-color: color.$green-2;
  }

  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: color.$green-2;
  }

  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    border-color: color.$green-2;
  }
}


// Reactive forms validation
form.ng-submitted.on-submit-verification {
  .custom-control-input.ng-invalid ~ .custom-control-label::before {
    border-color: color.$danger;
  }

  .custom-control-input.ng-invalid ~ :is(label) {
    color: color.$danger;

    a {
      color: color.$danger;
    }
  }
}
