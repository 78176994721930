@use "../variables/color";

.th-badge {
  padding: 3px 4px;

  font-size: 10px;
  font-weight: 500;

  border-radius: 4px;

  &--yellow {
    background-color: color.$yellow;
  }

  &--secondary {
    background-color: color.$secondary;
    color: color.$white;
  }

  &--black {
    background-color: color.$black;
    color: color.$white;
  }

  &--danger {
    background-color: color.$danger;
    color: color.$white;
  }

  &--gray{
    background-color: color.$gray-3;
    color: color.$white;
  }
}