@use 'variables/_util' as util;
@use 'bootstrap' as bootstrap;
@use 'sass:map' as sass-map;

@each $key, $weight in util.$font-weights {
  .font-weight-#{$key} {
    font-weight: $weight !important;
  }
}

@each $key, $rotate in util.$rotate-values {
  .rotate-#{$key} {
    transform: rotate($rotate);
  }
}

.font-size-sm {
  font-size: bootstrap.$font-size-sm !important;
}

.font-size-base {
  font-size: bootstrap.$font-size-base !important;
}

.font-size-lg {
  font-size: bootstrap.$font-size-lg !important;
}

// Space between (space-{side}-{breakpoint}-{size})
@each $breakpoint in map-keys(bootstrap.$grid-breakpoints) {
  @include bootstrap.media-breakpoint-up($breakpoint) {
    $infix: bootstrap.breakpoint-infix($breakpoint, bootstrap.$grid-breakpoints);

    @each $key, $space in bootstrap.$spacers {
      .space-x#{$infix}-#{$key} > * + * {
        margin-left: $space !important;
      }

      .space-y#{$infix}-#{$key} > * + * {
        margin-top: $space !important;
      }
    }
  }
}

// Gap for flex & grid (gap-{breakpoint}-{size})
@each $breakpoint in map-keys(bootstrap.$grid-breakpoints) {
  @include bootstrap.media-breakpoint-up($breakpoint) {
    $infix: bootstrap.breakpoint-infix($breakpoint, bootstrap.$grid-breakpoints);

    @each $key, $space in bootstrap.$spacers {
      .gap#{$infix}-#{$key} {
        gap: $space !important;
      }
    }
  }
}

// Expand the Sizing Utility to allow for responsive breakpoints
@each $breakpoint in map-keys(bootstrap.$grid-breakpoints) {
  @include bootstrap.media-breakpoint-up($breakpoint) {
    $infix: bootstrap.breakpoint-infix($breakpoint, bootstrap.$grid-breakpoints);

    @each $prop, $abbrev in (width: w, height: h) {
      @each $size, $length in bootstrap.$sizes {
        .#{$abbrev}#{$infix}-#{$size} {
          #{$prop}: $length !important;
        }
      }
    }
  }
}

.colon-after {
  &:after {
    content: ':';
  }
}

.asterisk-after {
  &:after {
    content: ' *';
  }
}

.pointer {
  cursor: pointer !important;
}

.whitespace-pre-wrap {
  white-space: pre-wrap !important;
}

.whitespace-normal {
  white-space: normal !important;
}

.selectable {
  -webkit-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  user-select: text !important;
}
