i-tabler {
  &.i-sm {
    width: 16px;
    height: 16px;
  }

  &.i-md {
    width: 20px;
    height: 20px;
  }

  &.i-lg {
    width: 28px;
    height: 28px;
  }

  &.i-xl {
    width: 32px;
    height: 32px;
  }
}
