@use 'variables/_color' as color;
@use 'bootstrap' as bootstrap;

@mixin ng-select-size($font-size, $input-height, $input-border-radius, $input-padding-y, $input-padding-x, $border-radius) {
  font-size: $font-size;

  &.ng-select-single {
    .ng-select-container {
      height: $input-height;

      .ng-value-container {
        .ng-input {
          top: 50%;
          left: 0;
          padding-left: $input-padding-x;
        }
      }
    }
  }

  &.ng-select-multiple {
    .ng-select-container {
      .ng-value-container {
        .ng-value {
          border-radius: $border-radius;
        }
      }
    }
  }

  .ng-select-container {
    min-height: $input-height;
    border-radius: $input-border-radius;

    .ng-value-container {
      padding: $input-padding-y $input-padding-x;
    }
  }
}

.ng-select {
  @include ng-select-size(
      bootstrap.$font-size-base,
      bootstrap.$input-height,
      bootstrap.$input-border-radius,
      bootstrap.$input-padding-y,
      bootstrap.$input-padding-x,
      bootstrap.$border-radius,
  );

  &.sm {
    @include ng-select-size(
        bootstrap.$font-size-sm,
        bootstrap.$input-height-sm,
        bootstrap.$input-border-radius-sm,
        bootstrap.$input-padding-y-sm,
        bootstrap.$input-padding-x-sm,
        bootstrap.$border-radius-sm,
    );
  }

  .ng-select-container {
    align-items: center;
    border: bootstrap.$input-border-width solid bootstrap.$input-border-color;
    color: bootstrap.$input-color;
    background-color: bootstrap.$input-bg;

    .ng-value-container {
      align-items: center;

      .ng-placeholder {
        color: bootstrap.$input-placeholder-color;
      }
    }
  }

  .ng-clear-wrapper {
    display: flex;
    align-items: center;
    width: 8px !important;

    .ng-clear {
      position: relative;
      white-space: nowrap;
      overflow: hidden;
      transition: 0.15s color;
    }

    &:hover {
      .ng-clear {
        color: color.$primary;
      }
    }
  }

  .ng-spinner-zone {
    padding: 5px 5px 0 0;
  }

  .ng-arrow-wrapper {
    width: 24px;
    margin-right: 5px;
    margin-left: 5px;

    .ng-arrow {
      border-color: bootstrap.$body-color transparent transparent;
      border-style: solid;
      border-width: 5px 5px 2.5px;
    }
  }

  &.ng-select-opened,
  &.ng-select-focused {
    .ng-select-container {
      border-color: bootstrap.$input-focus-border-color;
      box-shadow: bootstrap.$input-focus-box-shadow;
    }
  }

  &.ng-select-opened {
    .ng-arrow-wrapper {
      .ng-arrow {
        top: -2px;
        border-color: transparent transparent bootstrap.$body-color;
        border-width: 0 5px 5px;
      }
    }
  }

  &.ng-select-disabled,
  &[disabled] {
    pointer-events: none;

    .ng-select-container {
      color: color.$gray-3;
      background-color: #E9ECEF;
      border: 1px solid #CED4DA !important;
    }

    & ~ .invalid-feedback {
      display: none;
    }
  }

  .ng-has-value .ng-placeholder {
    display: none;
  }

  &.ng-select-single {
    .ng-select-container {
      .ng-value-container {
        .ng-input {
          top: 50%;
          left: 0;
          padding-right: 60px;
          transform: translateY(-50%);
        }

        input {
          color: bootstrap.$input-color;
        }
      }
    }
  }

  &.ng-select-multiple {
    &.ng-select-disabled {
      .ng-select-container .ng-value-container .ng-value {
        background-color: bootstrap.$input-disabled-bg;
        border: bootstrap.$input-border-width solid bootstrap.$input-border-color;

        .ng-value-label {
          padding: 0 5px;
        }
      }
    }

    .ng-select-container {
      .ng-value-container {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;

        .ng-value {
          display: flex;
          flex-direction: row-reverse;
          justify-content: center;
          border: bootstrap.$input-border-width solid bootstrap.$input-border-color;
          background-color: bootstrap.$input-bg;

          &.ng-value-disabled {
            background-color: bootstrap.$input-disabled-bg;

            .ng-value-label {
              padding-left: 5px;
            }
          }

          .ng-value-label {
            padding: 5px;
            font-size: 80%;
            line-height: 1;
          }

          .ng-value-icon {
            position: relative;
            width: 21px;
            height: 100%;
            border-left: bootstrap.$input-border-width solid bootstrap.$input-border-color;
            text-indent: 100%;
            white-space: nowrap;
            overflow: hidden;

            &:after {
              position: absolute;
              left: 50%;
              top: 0;
              width: 18px;
              height: 100%;
              content: '';
              mask-image: url('/asset/image/icon/point.svg');
              mask-size: contain;
              mask-repeat: no-repeat;
              mask-position: 50% 50%;
              background-color: bootstrap.$gray-500;
              transform: translateX(-50%);
              transition: background-color 0.15s;
            }

            &:hover {
              &:after {
                background-color: bootstrap.$gray-600;
              }
            }
          }
        }

        .ng-input {
          input {
            color: bootstrap.$input-color;
          }
        }
      }
    }
  }

  &.is-invalid, &.ng-invalid {
    .ng-select-container {
      border-color: bootstrap.$form-feedback-invalid-color;
    }

    &.ng-select-opened {
      .ng-select-container {
        border-color: bootstrap.$form-feedback-invalid-color;
        box-shadow: 0 0 0 bootstrap.$input-focus-width rgba(bootstrap.$form-feedback-invalid-color, 0.25);
      }
    }

    &.ng-select-focused:not(.ng-select-opened) {
      .ng-select-container {
        border-color: bootstrap.$form-feedback-invalid-color;
        box-shadow: 0 0 0 bootstrap.$input-focus-width rgba(bootstrap.$form-feedback-invalid-color, 0.25);
      }
    }
  }

  .ng-dropdown-panel {
    background-color: bootstrap.$dropdown-bg;
    border: bootstrap.$dropdown-border-width solid bootstrap.$dropdown-border-color;
    border-radius: bootstrap.$dropdown-border-radius;
    left: 0;

    &.ng-select-bottom {
      top: 100%;
      margin-top: 5px;
    }

    &.ng-select-top {
      bottom: 100%;
      margin-bottom: 5px;
    }

    .ng-dropdown-panel-items {
      .ng-option {
        &:first-child {
          border-top-right-radius: bootstrap.$dropdown-border-radius;
          border-top-left-radius: bootstrap.$dropdown-border-radius;
        }

        &:last-child {
          border-bottom-right-radius: bootstrap.$dropdown-border-radius;
          border-bottom-left-radius: bootstrap.$dropdown-border-radius;
        }
      }
    }

    .ng-dropdown-header {
      border-bottom: bootstrap.$dropdown-border-width solid bootstrap.$dropdown-border-color;
      padding: 5px 7px;
    }

    .ng-dropdown-footer {
      border-top: bootstrap.$dropdown-border-width solid bootstrap.$dropdown-border-color;
      padding: 5px 7px;
    }

    .ng-dropdown-panel-items {
      .ng-optgroup {
        user-select: none;
        padding: 5px 10px;
        font-weight: 600;
        font-size: bootstrap.$dropdown-font-size;
        cursor: pointer;

        &.ng-option-disabled {
          cursor: default;
        }

        &.ng-option-marked {
          background-color: color.$gray;
          color: bootstrap.$dropdown-link-hover-color;
        }

        &.ng-option-selected {
          background-color: bootstrap.$dropdown-link-active-bg;
          color: bootstrap.$dropdown-link-active-color;
        }
      }

      .ng-option {
        background-color: bootstrap.$dropdown-bg;
        color: bootstrap.$dropdown-color;
        padding: 5px 10px;
        font-size: bootstrap.$dropdown-font-size;

        &.ng-option-selected {
          color: bootstrap.$dropdown-link-active-color;
          background-color: bootstrap.$dropdown-link-active-bg;
        }

        &.ng-option-marked:not(.ng-option-selected) {
          background-color: rgba(color.$gray, .4);
          color: bootstrap.$dropdown-link-hover-color;
        }

        &.ng-option-disabled {
          color: bootstrap.$dropdown-link-disabled-color;
        }

        &.ng-option-child {
          padding-left: 22px;
        }

        .ng-tag-label {
          font-size: 80%;
          padding-right: 5px;
        }
      }
    }
  }
}