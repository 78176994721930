$blue: #0D448C;
$blue-1: #002A41;
$blue-2: #083166;
$green: #2EA39D;
$green-2: #35BDB6;
$green-3: #C9F4F2;
$white: #FFFFFF;
$gray: #F2F2F2;
$gray-2: #CCCCCC;
$gray-3: #999999;
$red: #D82E1F;
$red-2: #A52317;
$yellow: #FFE62E;

$primary: $green;
$secondary: $blue;
$black: $blue-1;
$success: $green;
$danger: $red;

$shadow-regular: 0px 8px 16px rgba(0, 0, 0, 0.15);
$shadow-small: 0px 2px 4px rgba(0, 0, 0, 0.075);
$shadow-larger: 0px 16px 48px rgba(0, 0, 0, 0.176);

$colors: (
  'primary': $primary,
  'secondary': $secondary,
  'black': $black,
  'success': $success,
  'danger': $danger,
  'blue': $blue,
  'blue-1': $blue-1,
  'green': $green,
  'green-2': $green-2,
  'green-3': $green-3,
  'white': $white,
  'gray': $gray,
  'gray-2': $gray-2,
  'gray-3': $gray-3,
  'red': $red,
  'yellow': $yellow,
);
