@use 'bootstrap' as bootstrap;

timepicker {
  margin-top: -1px !important;

  input {
    &::placeholder {
      opacity: 0 !important;
    }
  }

  &.is-invalid {
    input.form-control {
      border-color: bootstrap.$form-feedback-invalid-color !important;

      &:focus {
        box-shadow: 0 0 0 bootstrap.$input-btn-focus-width rgba(bootstrap.$form-feedback-invalid-color, 0.25) !important;
      }
    }
  }

  &.is-valid {
    input.form-control {
      border-color: bootstrap.$form-feedback-valid-color !important;

      &:focus {
        box-shadow: 0 0 0 bootstrap.$input-btn-focus-width rgba(bootstrap.$form-feedback-valid-color, 0.25) !important;
      }
    }
  }
}
