@use 'variables/_color' as color;
@use 'bootstrap' as bootstrap;

.modal-dialog.full-screen {
  max-width: 100%;
  height: auto;
  margin: 0;

  .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
}

modal-container {
  &.modal {
    z-index: 3000 !important;

    .modal-header {
      align-items: center;
      padding: 20px;
      border-bottom: none;

      .modal-title {
        padding-right: 40px;
        font-size: 20px;
      }

      .modal-control {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        height: 36px;
      }
    }

    .modal-body {
      font-size: 16px;
    }

    .modal-footer {
      border: none;
    }
  }

  button.close-button {
    background: none;
    border: none;
  }
}

bs-modal-backdrop {
  z-index: 2000 !important;
}
