@use 'variables/bootstrap';
@use 'variables/color';
@use 'variables/breakpoint';

$spacer: bootstrap.$font-size-base;

@forward '~bootstrap/scss/bootstrap' with (
  $body-bg: bootstrap.$body-bg,
  $body-color: bootstrap.$body-color,

  $primary: bootstrap.$primary,
  $secondary: bootstrap.$secondary,
  $success: bootstrap.$success,
  $danger: bootstrap.$danger,
  $yiq-contrasted-threshold: bootstrap.$yiq-contrasted-threshold,

  $link-color: color.$blue,

  $dropdown-link-hover-bg: bootstrap.$dropdown-link-hover-bg,
  $dropdown-link-active-bg: bootstrap.$dropdown-link-active-bg,

  $table-accent-bg: bootstrap.$table-accent-bg,
  $table-hover-bg: bootstrap.$table-hover-bg,

  $font-family-base: bootstrap.$font-family-base,
  $font-size-base: bootstrap.$font-size-base,
  $font-size-lg: bootstrap.$font-size-lg,
  $font-size-sm: bootstrap.$font-size-sm,

  $btn-font-weight: bootstrap.$btn-font-weight,
  $btn-padding-x: bootstrap.$btn-padding-x,
  $btn-padding-y: bootstrap.$btn-padding-y,
  $btn-padding-x-sm: bootstrap.$btn-padding-x-sm,
  $btn-padding-y-sm: bootstrap.$btn-padding-y-sm,

  $input-btn-focus-box-shadow: bootstrap.$input-btn-focus-box-shadow,

  $input-btn-padding-y: bootstrap.$input-btn-padding-y,
  $input-padding-x: bootstrap.$input-padding-x,
  $input-padding-y: bootstrap.$input-padding-y,
  $input-font-size: bootstrap.$input-font-size,
  $input-font-size-sm: bootstrap.$input-font-size-sm,
  $input-height: bootstrap.$input-height,
  $input-height-sm: bootstrap.$input-height-sm,
  $input-disabled-bg: bootstrap.$input-disabled-bg,
  $input-placeholder-color: bootstrap.$input-placeholder-color,

  $custom-control-indicator-focus-box-shadow: none,
  $custom-control-indicator-focus-border-color: color.$black,
  $custom-control-indicator-active-bg: color.$white,
  $custom-control-indicator-active-border-color: color.$black,
  $custom-control-label-disabled-color: color.$gray-3,
  $custom-control-indicator-disabled-bg: #E9ECEF,

  $custom-checkbox-indicator-border-radius: 2px,
  $custom-control-indicator-checked-border-color: color.$black,
  $custom-control-indicator-checked-bg: color.$black,
  $custom-control-indicator-checked-disabled-bg: color.$gray-3,

  $enable-validation-icons: false,

  $container-max-widths: (
    xs: bootstrap.$xs,
    sm: bootstrap.$sm,
    md: bootstrap.$md,
    lg: bootstrap.$lg,
    xl: bootstrap.$xl,
  ),

  $grid-breakpoints: (
    xs: breakpoint.$xs,
    sm: breakpoint.$sm,
    md: breakpoint.$md,
    lg: breakpoint.$lg,
    xl: breakpoint.$xl,
  ),

  $grid-columns: 12,
  $spacer: $spacer,
  $grid-gutter-width: 24px,
  $spacers: (
    0: 0,
    1: $spacer * .5,
    2: $spacer,
    3: $spacer * 1.5,
    4: $spacer * 2,
    5: $spacer * 2.5,
    6: $spacer * 3,
    7: $spacer * 3.5,
    8: $spacer * 4,
  ),
);
