@use 'variables/_color' as color;
@use 'bootstrap';

input.form-control {
  &:disabled {
    color: color.$gray-3;
    background-color: #E9ECEF;
    border: 1px solid #CED4DA;

    & ~ .invalid-feedback {
      display: none;
    }
  }
}

form.on-submit-verification.ng-submitted {
  textarea.form-control, input.textarea {
    &.ng-invalid {
      border-color: color.$danger;
    }
  }
}