@use 'variables/_color' as color;

.password-toggle {
  position: relative;

  > input {
    padding-right: 40px;

    ~ .password-toggle-icon {
      position: absolute;
      top: 50%;
      right: 0.75rem;
      display: block;
      width: 18px;
      height: 18px;
      mask-image: url('/asset/image/icon/eye.svg');
      mask-size: contain;
      mask-position: center;
      mask-repeat: no-repeat;
      background-color: color.$black;
      transform: translateY(-50%);
      cursor: pointer;
    }

    &[type="text"] ~ .password-toggle-icon {
      mask-image: url('/asset/image/icon/eye-off.svg');
    }

    &.is-valid {
      background-image: none;

      ~ .password-toggle-icon {
        background-color: color.$success;
      }
    }

    &.is-invalid {
      background-image: none;

      ~ .password-toggle-icon {
        background-color: color.$danger;
      }
    }
  }

  > label + input {
    ~ .password-toggle-icon {
      top: 40px;
      transform: none;
    }
  }
}
